/**
 * Collection of external pointers that may on occassions require updates.
 */


export const DOCUMENTATION_URL = "https://gitlab.com/petabite.eu/documentation";
export const TRACEABILITY_SERVICE_QUERY_URL_PREFIX = "https://trace.dataspace.copernicus.eu/traces?q=";
export const PROCESSING_DEMO_POINTER = `${DOCUMENTATION_URL}/pb-ieoto-demo-processor`;


