/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import { optionsMenuIcon } from '../Icons/Icons'
import './OptionsMenu.scss'
import { DropdownBase } from './DropdownBase';

export function OptionsMenu({ children, drop = 'down' }) {
	return (
		<DropdownBase toggleClass='optionsMenu' toggleContent={optionsMenuIcon} drop={drop}>
			{children}
		</DropdownBase>
	)
}
