/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable';

import { Helmet } from "react-helmet";
import { Fig } from '../components/Figure';
import { HelperIcon } from '../../common/Icons/Icons';
import { PROCESSING_DEMO_POINTER } from '../../../utils/externalpointers';

const DashboardImg = require('./processing.png')

export default function Processing() {
	return (<>
	<Helmet>
			<title>home / doc / processing</title>
		</Helmet>
		<h1>Processing</h1>

		<p>The processing page provides basic information on the configured hosted processing function and the status of processing requests.</p>


		<Fig src={DashboardImg} label="Processing"
			description="Processing page example" width="600px" />

		<Tbl caption="Page elements">
			<TblHead labels={["Item", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Workflows</TblCell>
					<TblCell>Lists the registered workflows that can be executed. For an example on how to deploy a workflow, please 
						check <a href={PROCESSING_DEMO_POINTER} target='_blank' rel="noreferrer" >this example</a>.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Jobs</TblCell>
					<TblCell>Lists the active and completed hosted processing jobs and provides access information for the results.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Reload</TblCell>
					<TblCell>Reloads an up to date state of the hosted processing subsystem.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><HelperIcon /></TblCell>
					<TblCell>Opens this help page.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>
	</>)
}




