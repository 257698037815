/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Route, Routes } from 'react-router-dom';

import { SN } from '../components/doc/components/ServiceName'
import { IconPetabite } from '../components/doc/components/IconPetabite'

/* eslint-disable import/no-webpack-loader-syntax */
import Intro from '../components/doc/00-introduction/introduction'
import Support from '../components/doc/00-introduction/support'
import Web from '../components/doc/20-web/web'
import WebSearch from '../components/doc/22-web-search/web-search'
import StoredSearches from '../components/doc/22-web-search/stored-searches';
import Bookmarks from '../components/doc/22-web-search/bookmarks';
import Dashboards from '../components/doc/22-web-search/dashboards'
import WebMap from '../components/doc/24-web-map/web-map'
import WebList from '../components/doc/26-web-list/web-list'
import Api from '../components/doc/40-api/api'
import Ack from '../components/doc/80-ack/ack'
import Terms from '../components/doc/85-terms/terms'
import Legal from '../components/doc/90-legal/legal'
import Imprint from '../components/doc/90-legal/imprint'
import { Helmet } from "react-helmet";
import { DocSubTocEntry } from '../components/common/SubTocEntry';
import Content from '../components/doc/23-content/content';
import Processing from '../components/doc/30-processing/processing';


const introSlug = "introduction"
const supportSlug = "support"
const webSlug = "web"
const webSearchSlug = "websearch"
const contentSlug = "content"
const storedSearchesSlug = "storedsearches"
const bookmarksSlug = "bookmarks"
const dashboardsSlug = "dashboards"
const webMapSlug = "webmap"
const webListSlug = "weblist"
const apiSlug = "api"
const ackSlug = "ack"
const termsSlug = "terms"
const processingSlug = "processing"
export const legalSlug = "legal"
export const imprintSlug = "imprint"

export const documentationToc = {
	introduction: { path: "introduction", title: "Introduction" },
	support: { path: supportSlug, title: "Support" },
	service: { path: webSlug, title: "Web service" },
	search: { path: webSearchSlug, title: "Search" },
	map: { path: webMapSlug, title: "Map view" },
	resultlist: { path: webListSlug, title: "List view" },
	datacontent: { path: contentSlug, title: "Data content" },
	storedsearches: { path: storedSearchesSlug, title: "Stored searches" },
	bookmarks: { path: bookmarksSlug, title: "Bookmarks" },
	dashboards: { path: dashboardsSlug, title: "Dashboards" },
	processing: { path: processingSlug, title: "Processing" },
	api: { path: apiSlug, title: "API functions" },
	attributions: { path: ackSlug, title: "Attributions" },
	terms: { path: termsSlug, title: "Terms and conditions" },
	privacy: { path: legalSlug, title: "Privacy policy" },
	imprint: { path: imprintSlug, title: "Imprint" }
}

function TableOfContent() {

	return (
		<Navbar expand="lg">
			<Navbar.Toggle />
			<Navbar.Collapse >
				<Nav className="flex-column">
					<DocSubTocEntry entrySpec={documentationToc.introduction}/>
					<DocSubTocEntry entrySpec={documentationToc.support}/>
					<DocSubTocEntry entrySpec={documentationToc.service}/>
					<DocSubTocEntry entrySpec={documentationToc.search}/>
					<DocSubTocEntry entrySpec={documentationToc.map}/>
					<DocSubTocEntry entrySpec={documentationToc.resultlist}/>
					<DocSubTocEntry entrySpec={documentationToc.datacontent}/>
					<DocSubTocEntry entrySpec={documentationToc.storedsearches}/>
					<DocSubTocEntry entrySpec={documentationToc.bookmarks}/>
					<DocSubTocEntry entrySpec={documentationToc.dashboards}/>
					<DocSubTocEntry entrySpec={documentationToc.processing}/>
					<DocSubTocEntry entrySpec={documentationToc.api}/>
					<DocSubTocEntry entrySpec={documentationToc.attributions}/>
					<DocSubTocEntry entrySpec={documentationToc.terms}/>
					<DocSubTocEntry entrySpec={documentationToc.privacy}/>
					<DocSubTocEntry entrySpec={documentationToc.imprint}/>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export function DocPage() {
	return (
		<>
			<Helmet>
				<title>IEOTO / documentation</title>
			</Helmet>
			<Container fluid className='doc-page m-0 h-100' >
				<Row className='h-100 gx-0'>
					<Col sm={12} lg={2} xl={2} className="p-0" >
						<div className="w-100 doc-page-header d-none d-lg-block" aria-label='Petabite icon'> <IconPetabite /> </div>
						<TableOfContent />
					</Col>
					<Col sm={12} lg={10} xl={10} className="p-0" >

						<h1 className="w-100 doc-page-header d-none d-lg-block"> <SN /> service documentation</h1>

						<Container className="doc-page-content" aria-label="Documentation content">
							<Routes>
								<Route path="/" element={<Intro/>}  />
								<Route path={introSlug} element={<Intro />}  />
								<Route path={supportSlug} element={<Support />}  />
								<Route path={webSlug} element={<Web/>}  />
								<Route path={webSearchSlug} element={<WebSearch/>}  />
								<Route path={contentSlug} element={<Content/>}  />
								<Route path={storedSearchesSlug} element={<StoredSearches/>}  />
								<Route path={bookmarksSlug} element={<Bookmarks/>}  />
								<Route path={dashboardsSlug} element={<Dashboards/>}  />
								<Route path={processingSlug} element={<Processing/>}  />
								<Route path={webMapSlug} element={<WebMap/>}  />
								<Route path={webListSlug} element={<WebList/>}  />
								<Route path={apiSlug} element={<Api/>}  />
								<Route path={ackSlug} element={<Ack/>}  />
								<Route path={termsSlug} element={<Terms/>}  />
								<Route path={legalSlug} element={<Legal/>}  />
								<Route path={imprintSlug} element={<Imprint/>}  />
							</Routes>
						</Container>
					</Col>
				</Row>
			</Container>
		</>
	);
}
