/**
 * Copyright (C) Petabite GmbH, 2023- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Nav from 'react-bootstrap/Nav'

type SubTocEntryProps = {
    path:string
    title:string
 }

export function SubTocEntry({ path, title }:SubTocEntryProps) {
	return <Nav.Item>
		<Nav.Link href={path} className='fs-5 fw-bold'>{title}</Nav.Link>
	</Nav.Item>
}

export const fullDocPath = (leaf:string) => "/page/doc/" + leaf;

export function DocSubTocEntry({entrySpec}:{entrySpec:SubTocEntryProps}) {
	return <SubTocEntry path={fullDocPath(entrySpec.path)} title={entrySpec.title} />
}