import React from 'react';
import { IconButton } from '../Icons/IconButton';
import { HelperIcon } from '../Icons/Icons';
import { fullDocPath } from '../SubTocEntry';


const openInNewTab = (url:string) => {
    window.open(url, "_blank", "noreferrer");
  };


interface HelperButtonProps {
    helpTopic: { path: string, title: string }
}



const HelperButton: React.FC<HelperButtonProps> = ({ helpTopic }: HelperButtonProps) => {
    const onClick = () => {
        openInNewTab(fullDocPath(helpTopic.path))
    }

    return (
        <IconButton className='helper-icon' onClick={onClick} icon={<HelperIcon title={`Go to ${helpTopic.title} documentation`}/> } />
    );
};

export default HelperButton;