import React from "react";
import { Helmet } from "react-helmet";
import { Fig } from "../components/Figure";
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
const DashboardImg = require('./dashboard.png')

export default function Dashboards() {
	return (<>
		<Helmet>
			<title>home / doc / dashboards</title>
		</Helmet>
		<h1>Dashboards</h1>

		<p>A Dashboard allows to have a quick information on the most up to date value from a set of data streams. The dashboad shows a small information pane for each
			configured thing and shows the location of the thing on a map. The map icons association with a network is color coded. (Note: We are working on a more accessible
			solution.)
		</p>

		<p>
			Please take into account that the information provided may not be up-to-date and that the average age of the data shown is dependent on the data storage strategy. 
			For a number of measurement networks there is a bulk ingestion strategy that may result in significant delay between measurement and data submission.   
		</p>
		{<Fig src={DashboardImg} label="Dashboard"
			description="Dashboard example" width="600px" />}

		<Tbl caption="UI Elements">
			<TblHead labels={["Element", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>My Dashboard</TblCell>
					<TblCell>The configured dashboard.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Reload</TblCell>
					<TblCell>The dashboard updates automatically every few minutes. Pressing reload causes an immediate re-fetch of all latest values.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Network panel</TblCell>
					<TblCell>For each network there is a rectangle grouping all configured items of the network.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Thing pane</TblCell>
					<TblCell>For each configured thing there is a rectangular pane in the Network panel that the thing belongs to. Within the pane, the latest value of
						a configured channel (display channel) is shown.
						From the context menu of the pane, the user can
						<ul>
							<li>zoom to the thing location on the map</li>
							<li>navigate to the resource page of the associated ALLTIME aggregation (Ressource page)</li>
							<li>if authorized, drop the element from the dashboard</li>
						</ul>
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Map</TblCell>
					<TblCell>Things with a position are shown on the map with their location. The dynamic recent location of a thing is only shown, when the
						the position channel of the network is configured in the standard form. If not, the position in the map is the centroid of the associated
						aggregations bounding box.

					</TblCell>
				</TblRow>

			</TblBody>
		</Tbl>

	</>)
}