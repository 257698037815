/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'

export const msgSlice = createSlice({
	name: 'msg',
	initialState: {
		messages: []
	},
	reducers: {
		addMessage: (state, action) => {
			state.messages = [...state.messages, action.payload];
		},
		removeMessage: (state, action) => {
			state.messages = state.messages.filter(item => action.payload !== item)
		},
	}
})

export const { addMessage, removeMessage } = msgSlice.actions

export const selectMessagesFromStoreState = (state) => {
	return {
		messages: state.msg.messages
	}
}

export const handleApiError = (dispatch) => {
	return (error) => {
		console.log("An error occurred!")
	

		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			const resp = `API-Request ${error.config.method.toUpperCase()} ${error.config.url} failed with code ${error.response.status}: ${error.response.statusText}`
			console.log(resp)
			dispatch(addMessage(resp))
		} else if (error.request) {
			// The request was made but no response was received
			console.log(error.message)
			dispatch(addMessage(error.message))
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log(error.message)
			dispatch(addMessage(error.message))
		}
	}
}
