/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { reloadIcon } from '../components/common/Icons/Icons';
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn';
import { refreshBookmarked, selectUserBookmarkedItemsFromStoreState } from '../state/store.userItemsBookmarked';
import { DataProductList } from '../components/data/DataProduct/DataProductList';
import { selectOidcTokensFromStoreState } from '../state/store.oidc';

import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { selectSelectedIdsFromStoreState } from '../state/store.dataProducts';
import { documentationToc } from './DocPage';
import HelperButton from '../components/common/Helper/HelperButton';

export function BookmarkedPage({ bookmarkedItems, selectedProductIds, dispatch, access_token }) {

	const reloadButtonClick = () => dispatch(refreshBookmarked())

	return (
		<Container fluid>
			<Row className="flex-row-reverse">
				<Col xs="auto" className="ml-auto p-0" >
					<Container fluid >
						<Row className="gx-0 my-2">
							<Col></Col>
							<Col xs="auto" className="ml-auto p-0">
								<Button variant="secondary" onClick={reloadButtonClick} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
							</Col>
							<Col xs="auto" className='p-0'>
								<HelperButton helpTopic={documentationToc.bookmarks} />
							</Col>
						</Row>
					</Container>
				</Col>
				<Col className="p-0">
					<DataProductList dispatch={dispatch} products={bookmarkedItems} selectedProductIds = {selectedProductIds} productFilter={null} setProductFilter={null} showOnlySelected={false} 
						access_token={access_token}
						isBookmarklistFull={true}
						bookmarkedProductIs={bookmarkedItems.map((item) => item.id)} 
						/>
				</Col>
			</Row>
		</Container>
	)
}

export function ConnectedBookmarkedPage() {
	const { selectedProductIds } = useSelector(selectSelectedIdsFromStoreState, shallowEqual)
	const { bookmarkedItems } = useSelector(selectUserBookmarkedItemsFromStoreState, shallowEqual)
	const { access_token } = useSelector(selectOidcTokensFromStoreState, shallowEqual)
	const dispatch = useDispatch()

	return <>
		<ConnectedOnlyLoggedIn loginHint>
			<Helmet>
				<title>IEOTO / stored searches</title>
			</Helmet>
			<BookmarkedPage dispatch={dispatch} 
				bookmarkedItems={bookmarkedItems.map((item)=>{return {...item.data, uuid:item.uuid}})}
				selectedProductIds={selectedProductIds} 
				access_token={access_token} />
		</ConnectedOnlyLoggedIn>
	</>
}

