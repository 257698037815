/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { PropsWithChildren, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { newChannelType } from './NetworkConfigurations';
import { ShowItemJson } from '../common/ShowItemJson';
import { toastWarning } from '../../utils/toastUtils';
import { ID_CHANNEL_DEV, SampleRecord } from './SampleRecord';
import { ManagedSensors } from './ManagedSensors';
import { EmergingThingType, ObservationChannel, ObservationNetwork } from '../../utils/types';
import { ChannelConfiguration } from './ChannelConfiguration';
import { useNavigate, useParams } from 'react-router';
import { pscoutApi } from '../../state/pscoutApi';
import { PATH_NETWORKS } from '../../pbconstants';
import {environmentConfig} from '../../state/environmentConfigLoader';



// style class of editable form control elements
export const FC_CLASSES = "bg-white"

function newEmptyEmergingThingType() {
	return { thingIdChannelName: "" } as EmergingThingType
}

export interface NetworkConfigurationProps {
	networkDefinition: ObservationNetwork
	editable: boolean
}

function ConfigurationSaveButton({ networkDefinition, editable }: NetworkConfigurationProps) {
	return <Button disabled={!editable}>Create new network version</Button>
}

function FormLabel({ children }: PropsWithChildren) {
	return <Form.Label className='fw-bold'>{children}</Form.Label>
}

export function NetworkConfiguration({ networkDefinition, editable }: NetworkConfigurationProps) {
	const [editNetworkDefinition, setEditNetworkDefinition] = useState(structuredClone<ObservationNetwork>(networkDefinition))
	const [newChannelName, setNewChannelName] = useState("")

	const timeChannel = editNetworkDefinition.observationType?.channels?.find((channel) => channel.name === editNetworkDefinition.observationType?.timeChannelName) as ObservationChannel | undefined
	const positionChannel = editNetworkDefinition.observationType?.channels?.find((channel) => channel.name === editNetworkDefinition.observationType?.positionChannelName) as ObservationChannel | undefined
	const tagsChannel = editNetworkDefinition.observationType?.channels?.find((channel) => channel.name === editNetworkDefinition.observationType?.tagsChannelName) as ObservationChannel | undefined

	const idChannel = (() => {
		const idChannelName = editNetworkDefinition.observationType?.emergingThingType?.thingIdChannelName
		return editNetworkDefinition.observationType?.channels?.find((channel) => channel.name === idChannelName) as ObservationChannel | undefined
	})()

	const freeChannels = (() => {
		const nonFreeChannelNames = [""] as Array<string>
		idChannel && nonFreeChannelNames.push(idChannel.name)
		timeChannel && nonFreeChannelNames.push(timeChannel.name)
		positionChannel && nonFreeChannelNames.push(positionChannel.name)
		tagsChannel && nonFreeChannelNames.push(tagsChannel.name)
		// how to handle ids
		return editNetworkDefinition.observationType?.channels?.filter((channel) => !nonFreeChannelNames.includes(channel.name))

	})()

	const deleteChannel = (channelName: string) => {
		const newNetworkDefinition = structuredClone(editNetworkDefinition)
		const channelNumber = newNetworkDefinition.observationType?.channels?.findIndex((element) => element.name === channelName)
		newNetworkDefinition.observationType?.channels?.splice(channelNumber ?? NaN, 1)
		setEditNetworkDefinition(newNetworkDefinition)
	}

	const addChannel = (channelName: string) => {
		const newNetworkDefinition = structuredClone(editNetworkDefinition)

		if (newNetworkDefinition.observationType?.channels?.find((element) => element.name === channelName)) {
			toastWarning("Cannot add channel, there is already one named " + channelName)
			return
		}
		newNetworkDefinition.observationType?.channels?.push(newChannelType(channelName))
		setEditNetworkDefinition(newNetworkDefinition)
	}

	const updateChannel = (channelName: string, channelType: ObservationChannel) => {
		const newNetworkDefinition = structuredClone(editNetworkDefinition)
		const idx = newNetworkDefinition.observationType?.channels?.findIndex((ct) => ct.name === channelName) ?? 0
		if (newNetworkDefinition.observationType?.channels) {
			newNetworkDefinition.observationType.channels[idx] = channelType
		}
		setEditNetworkDefinition(newNetworkDefinition)
	}

	const updateTimeChannel = (channelIndex: string, channelType: ObservationChannel) => {
		const newNetworkDefinition = structuredClone(editNetworkDefinition)
		const idx = newNetworkDefinition.observationType.channels.findIndex((ct) => ct.name === channelIndex)
		newNetworkDefinition.observationType.channels[idx] = channelType
		newNetworkDefinition.observationType.timeChannelName = channelType.name
		setEditNetworkDefinition(newNetworkDefinition)
	}

	const updatePositionChannel = (channelIndex: string, channelType: ObservationChannel) => {
		const newNetworkDefinition = structuredClone(editNetworkDefinition)
		if (channelType.name === "") {
			const idx = newNetworkDefinition.observationType.channels.findIndex((ct) => ct.name === channelIndex)
			if (idx !== -1) {
				newNetworkDefinition.observationType.channels.splice(idx, 1)
			} else {
				const idx = newNetworkDefinition.observationType.channels.findIndex((ct) => ct.name === newNetworkDefinition.observationType.positionChannelName)
				if (idx !== -1) {
					newNetworkDefinition.observationType.channels.splice(idx, 1)
				}
			}
			newNetworkDefinition.observationType.positionChannelName = undefined
			setEditNetworkDefinition(newNetworkDefinition)
		}
		else {
			const idx = newNetworkDefinition.observationType.channels.findIndex((ct) => ct.name === channelIndex)
			if (-1 === idx) {
				newNetworkDefinition.observationType.channels.push(channelType)

			} else {
				newNetworkDefinition.observationType.channels[idx] = channelType
			}
			newNetworkDefinition.observationType.positionChannelName = channelType.name
			setEditNetworkDefinition(newNetworkDefinition)
		}
	}

	return <div className='p-3'>
		<Form>
			<Row>
				<Col className="col-auto py-2">
					<Form.Check className='fw-bold'
						type="switch"
						aria-label="emerging"
						label="Emerging sensors"
						disabled={!editable}
						onChange={(e) => {
							const newNetworkDefinition = structuredClone(editNetworkDefinition)
							newNetworkDefinition.observationType.emergingThingType = newEmptyEmergingThingType()
							// assert that there is / is not an id channel
							if (newNetworkDefinition.observationType.emergingThingType) {
								if (!newNetworkDefinition.observationType.channels.find((element) => element.name === "id")) {
									newNetworkDefinition.observationType.channels.push(ID_CHANNEL_DEV)
								}
							} else {
								const idIndex = newNetworkDefinition.observationType.channels.findIndex((element) => element.name === "id")
								if (idIndex >= 0) {
									newNetworkDefinition.observationType.channels.splice(idIndex, 1)
								}
							}
							setEditNetworkDefinition(newNetworkDefinition)
						}}
						checked={editNetworkDefinition.observationType.emergingThingType ? true : false}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Network name</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.networkName} />
					</Form.Group>
				</Col>
				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Network Label</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.networkLabel} />
					</Form.Group>
				</Col>
				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Last active version</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.networkConfigVersion?.toString()} />
					</Form.Group>
				</Col>

				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Observation type</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text"
							value={editNetworkDefinition.observationType.typeName + " / " + editNetworkDefinition.observationType?.typeVersion} />
					</Form.Group>
				</Col>

				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Observation type label</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.observationType?.typeLabel} />
					</Form.Group>
				</Col>
				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Owner</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.ownerName} />
					</Form.Group>
				</Col>
				<Col xs={6} lg={4}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<FormLabel>Average daily records per sensor</FormLabel>
						<Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={editNetworkDefinition.observationType?.expectedAverageRecordsPerDay} />
					</Form.Group>
				</Col>
			</Row>

			<h1 className='mt-2'>Data stream channel configuration</h1>

			<Table bordered className="table-responsive" >
				<thead>
					<tr><th style={{ maxWidth: "12rem" }}>Name</th><th>Label</th><th>Description</th><th>Type</th><th>Config</th><th>Action</th></tr>
				</thead>
				<tbody>
					{idChannel && <ChannelConfiguration prefix={"ID channel"} editable={editable}
						onDelete={() => { }} updateChannel={updateChannel}
						channel={idChannel} fixed={true} nameEditingDisabled={true} />}
					{
						timeChannel && <ChannelConfiguration prefix="Time channel" editable={editable}
							onDelete={() => { }} updateChannel={updateTimeChannel}
							channel={timeChannel} fixed={true} nameEditingDisabled={false} />
					}
					{
						positionChannel && <ChannelConfiguration prefix="Position channel" editable={editable} onDelete={() => { }} updateChannel={updatePositionChannel}
							channel={positionChannel} fixed={true} nameEditingDisabled={false} />
					}
					{
						tagsChannel && <ChannelConfiguration prefix="Tags channel" editable={editable} onDelete={() => { }} updateChannel={(channelName: string, channelType: ObservationChannel) => console.log("update " + channelName, channelType)}
							channel={tagsChannel} fixed={true} nameEditingDisabled={false} />
					}
					<tr className="fw-bold"><td className="bg-insitu" colSpan={100}>Other channels</td></tr>
					{
						freeChannels.map((channel) => <ChannelConfiguration prefix="" editable={editable} key={channel.name}
							channel={channel} updateChannel={updateChannel} onDelete={deleteChannel} fixed={false} nameEditingDisabled={true} />)
					}
					{editable && <tr><td><input type="text" onChange={(ev) => setNewChannelName(ev.target.value)} /></td><td><Button onClick={() => addChannel(newChannelName)}>Add channel</Button></td><td colSpan={100}></td></tr>}
				</tbody>
			</Table>

			<Row>
				<Col className='col-auto ms-auto'><ShowItemJson product={editNetworkDefinition} /></Col>
				{editable && <Col className='col-auto'><ConfigurationSaveButton networkDefinition={editNetworkDefinition} editable={editable} /></Col>}
			</Row>

			{!editNetworkDefinition.observationType.emergingThingType && <ManagedSensors owner={networkDefinition.ownerName} networkName={networkDefinition.networkName} />}

			<h1>Example record</h1>

			<div>Please note that in-situ data storage is only available for paid accounts.</div>

			<SampleRecord networkDefinition={editNetworkDefinition} editable={editable} />

			<h1>Supply interface</h1>

			<div>To populate, records need to be supplied to
				&nbsp;<strong>POST {environmentConfig.api.url}/v1/things/{editNetworkDefinition.ownerName}/{editNetworkDefinition.networkName}/observation{!editNetworkDefinition.observationType?.emergingThingType && "/{thingId}"}</strong> using an appropriate access token.
			</div>

		</Form>
	</div>
}

interface ConnectedNetworkConfigurationProps {
    userProfile: any
}

export function ConnectedNetworkConfigurationPage({ userProfile }:ConnectedNetworkConfigurationProps) {
	const { networkName, networkOwner} = useParams();
	const navigate = useNavigate()
	const [networkDefinition, setNetworkDefinition] = useState(null as unknown as ObservationNetwork)

	useEffect(() => {
		const getInfo = async () => { 
			pscoutApi.getOneNetworkInfo(networkOwner,networkName).then(networksInfo => {
				if (networksInfo.items.length > 0 ) {
					setNetworkDefinition(networksInfo.items[0])
				}
			}
			).catch(error => {
				console.log(error)
			})
		}
		getInfo()

	}, [networkName,networkOwner])

	return <>
	<Row className="p-3">
                <Col><h2>{networkName}</h2></Col>
                <Col className="col-auto"><Button onClick={() => navigate(PATH_NETWORKS)}>Back</Button></Col>
            </Row>
	{networkDefinition && <NetworkConfiguration editable={false} networkDefinition={networkDefinition} />}
		
	</>

}
