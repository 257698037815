/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Container from 'react-bootstrap/Container'
import { DataProductListEntry } from '../DataProduct/DataProductListEntry'
import { toImage } from '../../../state/store.dataProducts'
import { MenuCtrl } from '../../../utils/MenueCtrl';
import { Col, Row } from 'react-bootstrap';
import { SupportEmail } from '../../doc/components/ServiceName';
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserProfileFromStoreState } from '../../../state/store.oidc';
import { ConnectedOnlyAnonymous } from '../../security/OnlyAnonymous';
import HomePageContent from '../../HomePageContent/HomePageContent';

export function DataProductList({ dispatch, products, selectedProductIds = [],
	productFilter, setProductFilter, showOnlySelected, access_token, isBookmarklistFull = false,
	bookmarkedProductIs = [] }) {
	const { userProfile } = useSelector(selectUserProfileFromStoreState, shallowEqual)


	var theProductsToShow = products
	if (showOnlySelected) {
		theProductsToShow = products.filter(product => selectedProductIds.includes(product.id))
	}

	if (products.length > 0) {
		return <Container>
			{theProductsToShow.map(product => {
				try {
					return <DataProductListEntry
						dispatch={dispatch}
						key={product.id}
						product={product}
						access_token={access_token}
						selected={selectedProductIds.includes(product.id)}
						thumbnailImage={toImage(product.thumbnailURL, access_token)}
						quicklookImage={toImage(product.quicklookURL, access_token)}
						productFilter={productFilter}
						setProductFilter={setProductFilter}
						bookmarkCtrl={new MenuCtrl(bookmarkedProductIs.includes(product.id),
							isBookmarklistFull, userProfile.id !== undefined)}
					/>
				} catch (e) {
					return <Row>
						<Col >Sorry, something went wrong, this entry cannot be visualised. Try reload, if the problem persists, please inform us via <SupportEmail body={"Could not render " + JSON.stringify(product)} /></Col>
					</Row>
				}
			})}
		</Container>
	}
	else {
		return <Container>
			<p>Nothing to show yet. Expand your search or bookmark search results.
			</p>
			<ConnectedOnlyAnonymous>
				<div className='welcome-page'>
					<div className="p-5 border rounded container-lg welcome-page-content text-center">
						<HomePageContent />
					</div>

				</div>
			</ConnectedOnlyAnonymous>
		</Container>
	}
}
