/*
* This is replaced by sed during build time, see .gitlab-ci.yml
*/
const _buildVersion = "production.1850d05df190440ef7ad2c4957d2d4b4a9a89cbf";
const _buildTime = "2025-04-01T11:53:53Z";

const version = _buildVersion.includes("@@@") ? 'workstation.0000000000000000000000000000000000000000' : _buildVersion;
const regex = /(.+)\.([0-9a-f]{40})/;
const versionParts = regex.exec(version);
const loadTime = new Date();

export const buildInfo = {
	version: version,
	commitRefName: versionParts[1],
	commitHash: versionParts[2],
	buildTime: _buildTime.includes("@@@") ? loadTime.toISOString() : _buildTime
};