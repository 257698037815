/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
import { Helmet } from "react-helmet"

import { InfoPointer } from '../components/InfoPointer'
import { SN } from '../components/ServiceName'
import { ReactNode } from 'react'


const A_PRODUCT_TYPES = "producttypes"


interface LicenseProps {
	children: ReactNode
}

function License({children}:LicenseProps):ReactNode {
	return <>This data is available under the {children}</>
}

function DCLicense() {
	return <License><a href="https://opendatacommons.org/licenses/dbcl/1-0/">Database Contents License (DbCL) v1.0</a></License>
}

function DeDeBy20License() {
	return <License><a href="https://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a> license </License>
}

function SourceHhBmv() {
  return <>"Freie und Hansestadt Hamburg, Behörde für Verkehr und Mobilitätswende, (BVM)"</>
}

function EmptyCell():ReactNode {
	return <TblCell><></></TblCell>
}

export default function Content() {
	return (<>
		<Helmet>
			<title>home / doc / content</title>
		</Helmet>
		<h1>Content</h1>

		<SN /> contains a in-situ data and EO product references.

		<p>In-situ data is organized in Networks that signify a set of attributes of the maintained data records.
			The structure of the data records can be seen in the Network configurations that are available for inspection
			in the Networks section of the user profile for registered users.</p>

		<p>EO products are organised in product types. The product type definition is usually available trough the original data provider.</p>

		<h1>In-Situ Data Networks</h1>

		<Tbl caption="Data Networks">
			<TblHead labels={["Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>ADSB</TblCell><TblCell>Petabite</TblCell><TblCell>Aircrafts (ADSB)</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>AIS</TblCell><TblCell>Petabite</TblCell><TblCell>Ships (AIS)</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SENCOM_NOISE</TblCell><TblCell>Sensor.Community</TblCell><TblCell>
					Sensorcommunity Noise sensor. <DCLicense />.
				</TblCell><TblCell><></></TblCell></TblRow>
				<TblRow><TblCell>SENCOM_PM</TblCell><TblCell>Sensor.Community</TblCell><TblCell>Sensorcommunity particulate matter sensor. <DCLicense />.</TblCell> <EmptyCell/></TblRow>
				<TblRow><TblCell>SENCOM_PTH</TblCell><TblCell>Sensor.Community</TblCell><TblCell>Sensorcommunity Pressure Temperature Humidity sensor. <DCLicense />.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SENCOM_RAD</TblCell><TblCell>Sensor.Community</TblCell><TblCell>Sensorcommunity radiation sensor. <DCLicense />.</TblCell><EmptyCell/></TblRow>
				<TblRow>
					<TblCell>WSV_PegelOnline</TblCell><TblCell>pegelonline.wsv.de</TblCell>
					<TblCell>Gauge data from WSV pegelonline.wsv.de <License><a href="https://www.govdata.de/dl-de/zero-2-0">DL-DE-&gt;Zero-2.0</a></License> license.</TblCell>
					<TblCell><a href="https://www.pegelonline.wsv.de/gast/start">Pegelonline</a></TblCell>
				</TblRow>
				<TblRow>
					<TblCell>TrafficChargingPoints</TblCell><TblCell>https://iot.hamburg.de/</TblCell>
					<TblCell>Electric charging stations in Hamburg <DeDeBy20License /> by <SourceHhBmv />.</TblCell>
					<TblCell><a href="https://transparenz.hamburg.de/open-data-796518">Open data Hamburg</a></TblCell>
				</TblRow>
				<TblRow>
					<TblCell>TrafficCounters</TblCell><TblCell>https://iot.hamburg.de/</TblCell>
					<TblCell>Vehicle and bicycle counters with various integration intervals in Hamburg <DeDeBy20License /> by <SourceHhBmv />. </TblCell>
					<TblCell><a href="https://transparenz.hamburg.de/open-data-796518">Open data Hamburg</a></TblCell>
				</TblRow>
				<TblRow>
					<TblCell>TrafficResources</TblCell><TblCell>https://iot.hamburg.de/</TblCell>
					<TblCell>Parking spaces and bikes for rent in Hamburg <DeDeBy20License /> by <SourceHhBmv />.</TblCell>
					<TblCell><a href="https://transparenz.hamburg.de/open-data-796518">Open data Hamburg</a></TblCell>
				</TblRow>
				<TblRow>
					<TblCell>(User owned networks)</TblCell><TblCell>Yourself</TblCell>
					<TblCell>With a "Basic account" you can define your own networks and submit your own data, via the published
						Service API.</TblCell>
					<TblCell><a href="https://gitlab.com/petabite.eu/documentation/pb-ieoto-public-api/-/blob/main/src/main/api/pb-pscout-api.openapi.yaml?ref_type=heads">IEOTO API</a></TblCell>
				</TblRow>
			</TblBody>
		</Tbl>

		<h1 id={A_PRODUCT_TYPES}>Product type and type groups</h1>

		<p>When selecting product types, you can either select specific type codes or labelled type code groups.
			The available type groups and product types are listed here with information on type group content, a brief description of the codes
			and, where available, pointers to further information.
		</p>

		{/* Insert type data here (from pscout_typesinfo.py in central tools)*/}

		<h2>Missions</h2>
		<p>Mission related groupings</p>
		<Tbl caption="Missions">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Petabite</TblCell><TblCell>Petabite</TblCell><TblCell>Products created and provided by Petabite.
				</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-1</TblCell><EmptyCell/><TblCell>The most used product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-1 more</TblCell><EmptyCell/><TblCell>Less frequently requested product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-2</TblCell><EmptyCell/><TblCell>The most used product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-3</TblCell><EmptyCell/><TblCell>The most used product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-3 more</TblCell><EmptyCell/><TblCell>Less frequently requested product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-5P</TblCell><EmptyCell/><TblCell>The most used product types of the mission.</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Sentinel-5P more</TblCell><EmptyCell/><TblCell>Less frequently requested product types of the mission.</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Popular</h2>
		<p>-</p>
		<Tbl caption="Popular">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Popular</TblCell><EmptyCell/><TblCell>-</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sensing types</h2>
		<p>Sensor related groupings</p>
		<Tbl caption="Sensing types">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>In-situ</TblCell><EmptyCell/><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Optical</TblCell><EmptyCell/><TblCell>Most used optical products
				</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SAR</TblCell><EmptyCell/><TblCell>Most used SAR product types</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Spectral</TblCell><EmptyCell/><TblCell>Products from Spectral and Hyperspectral Instruments</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Topics</h2>
		<p>Topic related groupings</p>
		<Tbl caption="Topics">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Aerosols and Trace Gases</TblCell><EmptyCell/><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>Marine</TblCell><EmptyCell/><TblCell>-</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Aerosols and Trace Gases</h2>
		<p>-</p>
		<Tbl caption="Aerosols and Trace Gases">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>In-situ</h2>
		<p>-</p>
		<Tbl caption="In-situ">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Marine</h2>
		<p>-</p>
		<Tbl caption="Marine">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Optical</h2>
		<p>Most used optical products
		</p>
		<Tbl caption="Optical">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Petabite</h2>
		<p>Products created and provided by Petabite.
		</p>
		<Tbl caption="Petabite">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Popular</h2>
		<p>-</p>
		<Tbl caption="Popular">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>SAR</h2>
		<p>Most used SAR product types</p>
		<Tbl caption="SAR">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-1</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-1">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-1 more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-1 more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDF_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_RAW_0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-2</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-2">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-3</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-3">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>SL_1_RBT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_FRP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_LST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_WST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SY_1_MISR__</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_AOD___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_SYN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_V10___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VG1___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VGP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-3 more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-3 more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>SR_1_SRA_A_</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1A Echos parameters for SAR mode</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA_BS</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1b-S Echos parameters for SAR mod</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_LAN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Land</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_WAT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Water</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-5P</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-5P">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>RA_BDM</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><EmptyCell/></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-5P more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-5P more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>NP_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NP_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NP_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Spectral</h2>
		<p>Products from Spectral and Hyperspectral Instruments</p>
		<Tbl caption="Spectral">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Product types</h2>
		<p>All available product types</p>
		<Tbl caption="Product types">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NP_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NP_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>NP_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BDM</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>S1_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDF_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SL_1_RBT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_FRP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_LST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SL_2_WST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_RAW_0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><EmptyCell/></TblRow>
				<TblRow><TblCell>SR_1_SRA_A_</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1A Echos parameters for SAR mode</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA_BS</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1b-S Echos parameters for SAR mod</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_LAN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Land</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_WAT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Water</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SY_1_MISR__</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_AOD___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_SYN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_V10___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VG1___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VGP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>

		{/* End insert type data */}

	</>)
}