/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */



import React from 'react';

import "react-datetime/css/react-datetime.css";
import './DateTimeInput.css'
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';

import {
	temporalCoverageStartIcon
} from '../../data/DataProduct/DataProductAttributeIcons'
import { DropdownBase } from '../OptionsMenu/DropdownBase';

export function toUtcDayBegin(date) {
	const theTestDate = new Date(date)
	theTestDate.setUTCHours(0)
	theTestDate.setUTCMinutes(0)
	theTestDate.setUTCSeconds(0)
	theTestDate.setUTCMilliseconds(0)
	return theTestDate
}

export function toUtcHourBegin(date) {
	const theTestDate = new Date(date)
	theTestDate.setUTCMinutes(0)
	theTestDate.setUTCSeconds(0)
	theTestDate.setUTCMilliseconds(0)
	return theTestDate
}

function isEmpty(str) {
	return (!str || str.length === 0);
}

export function IntervalSetter({ intervalStart, intervalEnd, onIntervalChange }) {

	const clear = () => {
		onIntervalChange({ intervalStart: "", intervalEnd: "", relIntervalStart: null, relIntervalEnd: null })
	}

	const today = () => {
		onIntervalChange({ intervalStart: toUtcDayBegin(new Date()).toUTCString(), intervalEnd: "", relIntervalStart: null, relIntervalEnd: null })
	}

	const yesterday = () => {
		const dayEnd = toUtcDayBegin(new Date())
		onIntervalChange({ intervalStart: new Date(dayEnd - 24 * 60 * 60 * 1000).toUTCString(), intervalEnd: dayEnd.toUTCString(), relIntervalStart: null, relIntervalEnd: null })
	}

	const last24h = () => {
		onIntervalChange({ intervalStart: toUtcHourBegin(new Date(new Date() - 24 * 60 * 60 * 1000)).toUTCString(), intervalEnd: "", relIntervalStart: null, relIntervalEnd: null })
	}

	const last7d = () => {
		onIntervalChange({ intervalStart: toUtcDayBegin(new Date(new Date() - 24 * 60 * 60 * 1000 * 7)).toUTCString(), intervalEnd: "", relIntervalStart: null, relIntervalEnd: null })
	}

	const last30d = () => {
		onIntervalChange({ intervalStart: toUtcDayBegin(new Date(new Date() - 24 * 60 * 60 * 1000 * 31)).toUTCString(), intervalEnd: "", relIntervalStart: null, relIntervalEnd: null })
	}

	var backInterval = null
	var forwardInterval = null

	if (!isEmpty(intervalStart) && !isEmpty(intervalEnd)) {
		const s = moment.utc(intervalStart)
		const e = moment.utc(intervalEnd)
		const diff = e.diff(s)

		forwardInterval = () => {
			const newIntervalStart = e.toDate().toUTCString()
			e.add(diff, 'milliseconds') // awkward mutated object
			const rval = { intervalStart: newIntervalStart, intervalEnd: (e.toDate()).toUTCString(), relIntervalStart: null, relIntervalEnd: null }
			onIntervalChange(rval)
		}
		backInterval = () => {
			const newIntervalEnd = s.toDate().toUTCString()
			s.add(-diff, 'milliseconds') // awkward mutated object
			const rval = { intervalStart: s.toDate().toUTCString(), intervalEnd: newIntervalEnd, relIntervalStart: null, relIntervalEnd: null }
			onIntervalChange(rval)
		}
	}

	return (<div className="col-auto me-auto nav-item pl-0">
		<DropdownBase toggleClass='p-1' toggleContent={temporalCoverageStartIcon}>
			<Dropdown.Item onClick={today} >Today</Dropdown.Item>
			<Dropdown.Item onClick={yesterday} >Yesterday</Dropdown.Item>
			<Dropdown.Item onClick={last24h} >Last 24 hours</Dropdown.Item>
			<Dropdown.Item onClick={last7d} >Last 7 days</Dropdown.Item>
			<Dropdown.Item onClick={last30d} >Last 30 days</Dropdown.Item>
			{forwardInterval && <Dropdown.Item onClick={forwardInterval} >Interval forward</Dropdown.Item>}
			{backInterval && <Dropdown.Item onClick={backInterval} >Interval backward</Dropdown.Item>}
			<Dropdown.Item onClick={clear} >Clear Interval</Dropdown.Item>
		</DropdownBase>
	</div>
	)
}