/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Helmet } from "react-helmet";
import { Route, Routes } from 'react-router-dom';
import { ConnectedPscoutNavigationBar } from './components/PscoutNavigationBar/PscoutNavigationBar';
import { ConnectedServiceFooter } from './components/ServiceFooter/ServiceFooter';
import { pbSquaresUrl } from "./components/doc/components/PetabiteSquaresImage";
import { ConnectedAccessTokenPage } from './debug/AccessTokenPage/AccessTokenPage';
import { TestPage } from './debug/TestPage';
import { PATH_DASHBOARD, PATH_PROFILE, serviceName } from "./pbconstants";
import { ConnectedApiClientAccessTokenPage } from './pages/ApiClientAccessTokenPage';
import { ConnectedDataPage } from './pages/DataPage';
import { ConnectedDataProductDetailsPage } from './pages/DataProductDetailsPage';
import { DocPage } from './pages/DocPage';
import { HomePage } from './pages/HomePage';
import { ConnectedUserFilterPage } from './pages/UserFilterPage';
import { ConnectedBookmarkedPage } from "./pages/BookmarkedPage";
import { ConnectedUserProfilePage } from './pages/UserProfilePage';
import { ConnectedProcessingPage } from "./pages/ProcessingPage";
import { ErrorBoundary } from "react-error-boundary";
import { SupportEmail } from "./components/doc/components/ServiceName";
import { Toaster } from 'react-hot-toast';
import { ConnectedDashboardPage } from "./pages/DashboardPage";

//FIXME not used: 
//import { RegistrationCallback } from './components/RegistrationCallback/RegistrationCallback';
if (process.env["NODE_ENV"] !== "development") {
	console.log = () => { };
	console.debug = () => { };
	console.info = () => { };
	console.warn = () => { };
}

export function ConnectedApp() {

	return (<>
		<ErrorBoundary fallback={<div title="Could not render attribute value">We are very sorry, a major error occurred. Please reload the page to recover and contact <SupportEmail body={"global"} />, if the problem persists.</div>}>
			<Toaster />
			<header id="app-header" >
				<Helmet>
					<title>{serviceName}</title>
					<meta property="og:title" content={serviceName} />
					<meta property="og:type" content="website" />
					<meta property="og:image" content={pbSquaresUrl} />
					<meta property="og:url" content={window.location.href} />
				</Helmet>
				<ConnectedPscoutNavigationBar />
			</header>
			<main id="app-main" role="main" className="flex-grow-1" aria-label="main application screen">
				<Routes>
					<Route path="/home" element={<HomePage />} exact />
					<Route path="/" element={<ConnectedDataPage />} exact />
					<Route path="/page/dataProducts" element={<ConnectedDataPage />} exact />
					<Route path="/page/userFilter" element={<ConnectedUserFilterPage />} exact />
					<Route path="/page/bookmarked" element={<ConnectedBookmarkedPage />} exact />
					<Route path={PATH_DASHBOARD } element={<ConnectedDashboardPage />} exact />
					<Route path={PATH_DASHBOARD + "/:dashboardName"} element={<ConnectedDashboardPage />} exact />
					<Route path="/page/processing/*" element={<ConnectedProcessingPage />} exact />
					<Route path="/page/userProfile" element={<ConnectedUserProfilePage />} exact />
					<Route path={PATH_PROFILE + "/*"} element={<ConnectedUserProfilePage />} />
					<Route path="/page/apiClientAccessToken" element={<ConnectedApiClientAccessTokenPage />} exact />
					<Route path="/page/product/:productName" element={<ConnectedDataProductDetailsPage />} exact />
					<Route path="/page/debugAccessToken" element={<ConnectedAccessTokenPage />} exact />
					<Route path="/page/test" element={<TestPage />} exact />
					<Route path="/page/doc/*" element={<DocPage />} />
				</Routes>
			</main>
			<footer className="footer mt-auto p-2" id="app-footer">
				<ConnectedServiceFooter />
			</footer>
		</ErrorBoundary>
	</>
	);
}
