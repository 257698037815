/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Button from 'react-bootstrap/Button'

export const DEFAULT_VARIANT = "outline-secondary"

/**
 * A default button represented by an Icon.
 */
export function IconButton({ className="", icon, onClick }) {
	return <Button className ={`mx-1 ${className}`} variant={DEFAULT_VARIANT} onClick={onClick}>{icon}</Button>
}

/**
 * A default button represented by an Icon.
 */
export function IconSubmitButton({ icon }) {
	return <Button className ="m-1" variant={DEFAULT_VARIANT} type="submit">{icon}</Button>
}
