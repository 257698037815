/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'
import { pscoutApi } from './pscoutApi'
import { handleApiError } from './store.msg'
import { environmentConfig } from './environmentConfigLoader';
import toBBox from 'geojson-bounding-box'
import moment from 'moment';
import { ProductFilterOperator, ProductProductClassEnum } from '../utils/types';
import { AttributesEnum } from '../utils/ApiTypes';
import { selectOidcAccessTokenFromStoreState } from './store.oidc';
import { classWithNameMatchCondition, postProcessInsituFilters } from './dataProducts-utils';
import { emptyProductFilter } from './emptyProductFilter';

export const LOADING_IN_PROGRESS = 'LOADING_IN_PROGRESS'
export const NOT_FOUND = 'NOT_FOUND'

export const emptyProductTypesInfo = {
	groups: [],
	productTypes: []
}

/**
 * Convenience functions to create a filter objects
 */
export function createBoxFromArray(pts) {
	const boundingBox = {}
	boundingBox.west = Math.min(pts[0], pts[2])
	boundingBox.east = Math.max(pts[0], pts[2])
	boundingBox.north = Math.max(pts[1], pts[3])
	boundingBox.south = Math.min(pts[1], pts[3])
	return boundingBox
}

export function createBoxFromPt(pt) {
	const boundingBox = {}
	boundingBox.west = pt.longitude
	boundingBox.east = pt.longitude
	boundingBox.north = pt.latitude
	boundingBox.south = pt.latitude
	return boundingBox
}


export const createInSituProductFilter = (product) => {
	var s = moment.utc(product.temporalCoverageEnd)
	s.add(1, 'seconds'); // ensure that we have a time interval for products that have same start/stop time
	const endTimeString = (s.toDate()).toISOString()
	const productFilter = JSON.parse(JSON.stringify(emptyProductFilter));
	productFilter.boundingBox = createBoxFromArray(toBBox(product.spatialCoverage));
	productFilter.timeFilter.intervalStart = product.temporalCoverageStart;
	productFilter.timeFilter.intervalEnd = endTimeString;
	productFilter.eoFilters = [
		{
			key: AttributesEnum.productName,
			op: ProductFilterOperator.Eq,
			value: product.productName
		}
	];
	return productFilter;
}

export const dataProductsSlice = createSlice({
	name: 'dataProducts',
	initialState: {
		productFilter: emptyProductFilter,
		loading: false,
		products: [],
		appliedProductFilter: emptyProductFilter,
		selectedProductIds: [],
		product: null
	},
	reducers: {
		productFilterChanged: (state, action) => {
			state.productFilter = { ...action.payload }
		},
		dataProductsLoading: (state, action) => {
			state.loading = true;
			state.products = [];
			state.appliedProductFilter = emptyProductFilter;
		},
		dataProductsLoaded: (state, action) => {
			const { products, productFilter } = action.payload
			state.loading = false;
			state.error = false;
			state.errorMessage = undefined;
			state.products = products;
			state.appliedProductFilter = productFilter;
			state.selectedProductIds = [];
		},
		dataProductsLoadingFailed: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
			state.products = [];
			state.appliedProductFilter = emptyProductFilter;
		},
		dataProductLoaded: (state, action) => {
			state.product = action.payload;
		},
		clearDataProductsSelections: (state, action) => {
			state.selectedProductIds = []
		},
		dataProductsSelected: (state, action) => {
			const { affectedProductIds, isSelected } = action.payload;
			const selectedProductIdsSet = new Set(state.selectedProductIds);
			if (isSelected) {
				affectedProductIds.forEach(selectedProductIdsSet.add, selectedProductIdsSet)
			} else {
				affectedProductIds.forEach(selectedProductIdsSet.delete, selectedProductIdsSet)
			}
			state.selectedProductIds = Array.from(selectedProductIdsSet)
		},
	}
})

export const {
	productFilterChanged,
	dataProductsLoading,
	dataProductsLoaded,
	dataProductsLoadingFailed,
	dataProductLoaded,
	clearDataProductsSelections,
	dataProductsSelected,
} = dataProductsSlice.actions


dataProductsSlice.middleware = (store) => (next) => (action) => {
	let result = next(action);
	return result;
}


export const selectDataProductsFilterFromStoreState = (state) => {
	return {
		productFilter: state.dataProducts.productFilter
	}
}
export const selectDataProductsFromStoreState = (state) => {
	return {
		loading: state.dataProducts.loading,
		error: state.dataProducts.error,
		errorMessage: state.dataProducts.errorMessage,
		products: state.dataProducts.products,
		appliedProductFilter: state.dataProducts.appliedProductFilter,
		selectedProductIds: state.dataProducts.selectedProductIds,
	}
}

export const selectSelectedIdsFromStoreState = (state) => {
	return {
		selectedProductIds: state.dataProducts.selectedProductIds,
	}
}

const imageProductLoadingInProgress = {
	missing: true,
	loading: true,
	notFound: false,
	imageUrl: undefined,
};

const productLoadingInProgress = {
	product: null,
	thumbnailImage: imageProductLoadingInProgress,
	quicklookImage: imageProductLoadingInProgress,
};

const imageNotFound = {
	missing: true,
	loading: false,
	notFound: true,
	imageUrl: undefined,
};

export function toImage(imageUrl, access_token) {
	if (imageUrl === undefined || imageUrl === null) {
		return imageNotFound;
	} else if (pscoutApi.isThumbnailOrQuicklookApiUrl(imageUrl) && access_token) {
		return {
			missing: false,
			loading: false,
			notFound: false,
			imageUrl: `${imageUrl}?access_token=${access_token}`
		}
	} else {
		return {
			missing: false,
			loading: false,
			notFound: false,
			imageUrl: imageUrl,
		}
	}
}


export const selectDataProductFromStoreState = (state) => {
	if (state.dataProducts.product === LOADING_IN_PROGRESS) {
		return productLoadingInProgress;
	} else {
		const access_token = selectOidcAccessTokenFromStoreState(state);
		return {
			product: state.dataProducts.product,
			thumbnailImage: toImage(state.dataProducts.product.thumbnailURL, access_token),
			quicklookImage: toImage(state.dataProducts.product.quicklookURL, access_token),
		}
	}
}

export const setProductFilterAndDoSearch = (productFilter) => async (dispatch) => {
	try {
		dispatch(productFilterChanged(productFilter));
		dispatch(doSearch())
	} catch (err) {
		console.error(err);
	}
};

export const expandSpatialCoverageProduct = (product) => {
	if (product && product.spatialCoverageGeoJson) {
		return { ...product, spatialCoverage: JSON.parse(product.spatialCoverageGeoJson) }
	} else if (product && product.spatialCoverage) {
		return { ...product, spatialCoverage: JSON.parse(product.spatialCoverage) }
	} else {
		return product
	}
}

function calcPublishedBeforeTime() {
	const publishedBeforeTime = new Date(new Date().getTime() - environmentConfig.api.minPublishedAgeInSeconds * 1000);

	publishedBeforeTime.setUTCMinutes(0, 0, 0); // Truncate, result in more stable queries for caching

	return publishedBeforeTime
}

/**
 * ensures longitude (east-west) is in allowed range -180:180
 */
function normalizeLongitude(longitude) {
	var normalizedLongitude = longitude
	while (normalizedLongitude > 180) {
		normalizedLongitude -= 360;
	}
	while (normalizedLongitude < -180) {
		normalizedLongitude += 360;
	}
	return normalizedLongitude;
}

// temporarily implemented twice to have a typescript version, see RelTimeInput.tsx
export function isTimelimitSet(timeLimit) {
	if (timeLimit) {
		if (timeLimit.days + timeLimit.hours !== 0) {
			return true
		}
	}
	return false
}

export function isRelativeTimeFilter(productFilter) {
	return ((productFilter.relIntervalStart && isTimelimitSet(productFilter.relIntervalStart))
		|| (productFilter.relIntervalEnd && isTimelimitSet(productFilter.relIntervalEnd)))
}

export function deriveAggregationLevel(apiTimeFilter) {
	var start = moment(apiTimeFilter.intervalStart ?? "1970-01-01T00:00:00Z");
	var end = moment(apiTimeFilter.intervalEnd ?? moment.now());
	var lengthInMs = end.diff(start);
	if (lengthInMs <= moment.duration(1, "hours").asMilliseconds()) {
		return 'HOUR';
	} else if (lengthInMs <= moment.duration(1, "days").asMilliseconds()) {
		return 'DAY';
	} else if (lengthInMs <= moment.duration(31, "days").asMilliseconds()) {
		return 'MONTH';
	} else if (lengthInMs <= moment.duration(366, "days").asMilliseconds()) {
		return 'YEAR';
	} else {
		return 'ALLTIME';
	}
}

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss"

export const doSearch = () => async (dispatch, getState) => {
	try {
		const { productFilter } = selectDataProductsFilterFromStoreState(getState())

		const { apiTimeFilter, apiAoiBox, apiPageRequest, apiSortRequest, apiProductQuery } = prepareFilter(productFilter);

		dispatch(dataProductsLoading());

		const apiUrlData = { ...apiTimeFilter, ...apiAoiBox, ...apiPageRequest, ...apiSortRequest }
		const apiBodyData = { queries: apiProductQuery }

		pscoutApi.getDataProducts(apiUrlData, apiBodyData)
			.then((productsPage) => {
				if (productsPage) {
					const products = productsPage.items.map(expandSpatialCoverageProduct)
					dispatch(dataProductsLoaded({ products, productFilter }))
				}
			}).catch((error) => {

				dispatch(dataProductsLoadingFailed(""));
				handleApiError(dispatch)(error)
			})
	} catch (err) {
		console.error(err);
	}
}

export const doLoadProduct = (productName) => async (dispatch, getState) => {
	if (getState().dataProducts.product !== LOADING_IN_PROGRESS) {
		try {
			dispatch(dataProductLoaded(LOADING_IN_PROGRESS))
			pscoutApi.getProductByName(productName)
				.then((product) => {
					if (product) {
						const expandedProduct = expandSpatialCoverageProduct(product)
						dispatch(dataProductLoaded(expandedProduct))
					}
				}).catch((error) => {
					handleApiError(dispatch)(error)
				})
		} catch (err) {
			console.error(err);
		}
	}
}

export const unconnectedLoadProduct = async (dispatch, productName, setProduct) => {
	pscoutApi.getProductByName(productName)
		.then((product) => {
			setProduct(expandSpatialCoverageProduct(product))
		})
		.catch((error) => {
			handleApiError(dispatch)(error)
		})
}

export const unconnectedBoundigBoxToPolygon = async (dispatch, boundigBox, setPolygon) => {
	pscoutApi.boundigBoxToGeoJson(boundigBox)
		.then((polygon) => {
			if (polygon) {
				setPolygon(polygon)
			}
		})
		.catch((error) => {
			handleApiError(dispatch)(error)
		})
}

export const unconnectedGetVersionInfo = async (dispatch, setVersionInfo) => {
	pscoutApi.getVersionInfo()
		.then((versionInfo) => {
			if (versionInfo) {
				setVersionInfo(versionInfo)
			}
		})
		.catch((error) => {
			handleApiError(dispatch)(error)
		})
}

function prepareFilter(productFilter) {
	const timeFilter = productFilter.timeFilter;
	const apiTimeFilter = {
		//publishedAfter: null, // not used by UI
		publishedBefore: calcPublishedBeforeTime().toUTCString(),
		//lastModifiedAfter: null, // not used by UI
		//lastModifiedBefore: null, // not used by UI
	};
	if (isRelativeTimeFilter(timeFilter)) {
		if (timeFilter.relIntervalStart && isTimelimitSet(timeFilter.relIntervalStart)) {
			const theDate = moment.utc(new Date());
			theDate.add(timeFilter.relIntervalStart.days, 'days');
			theDate.add(timeFilter.relIntervalStart.hours, 'hours');
			apiTimeFilter.intervalStart = theDate.format(DATE_FORMAT) + "Z";
		}
		if (timeFilter.relIntervalEnd && isTimelimitSet(timeFilter.relIntervalEnd)) {
			const theDate = moment.utc(new Date());
			theDate.add(timeFilter.relIntervalEnd.days, 'days');
			theDate.add(timeFilter.relIntervalEnd.hours, 'hours');
			apiTimeFilter.intervalEnd = theDate.format(DATE_FORMAT) + "Z";
		}
	} else {
		if (timeFilter.intervalStart) {
			apiTimeFilter.intervalStart = timeFilter.intervalStart;
		}
		if (timeFilter.intervalEnd) {
			apiTimeFilter.intervalEnd = timeFilter.intervalEnd;
		}
	}
	const boundingBox = productFilter.boundingBox;
	const apiAoiBox = {};
	//aoiBox
	if (boundingBox) {
		apiAoiBox.north = boundingBox.north;
		apiAoiBox.west = normalizeLongitude(boundingBox.west);
		apiAoiBox.south = boundingBox.south;
		apiAoiBox.east = normalizeLongitude(boundingBox.east);
	}

	const apiPageRequest = {};
	if (productFilter.pageNumber) {
		apiPageRequest.pageNumber = productFilter.pageNumber;
	}
	const apiSortRequest = {};
	if (productFilter.sortRequest && productFilter.sortRequest.length > 0) {
		apiSortRequest.sortRequest = productFilter.sortRequest;
	} else {
		// default sorting logic
		const classWithNameMatch = classWithNameMatchCondition(productFilter);
		if (classWithNameMatch) {
			if (classWithNameMatch === ProductProductClassEnum.EO) {
				apiSortRequest.sortRequest = [AttributesEnum.productClass + " ASC", AttributesEnum.temporalCoverageStart + " DESC", AttributesEnum.productName + " ASC"];
			} else {
				apiSortRequest.sortRequest = [AttributesEnum.productClass + " DESC", AttributesEnum.temporalCoverageStart + " DESC", AttributesEnum.productName + " ASC"];
			}
		} else {
			apiSortRequest.sortRequest = [AttributesEnum.temporalCoverageStart + " DESC", AttributesEnum.productName + " ASC"];
		}

	}

	//productQuery
	const apiProductQuery = prepareProductsPropertyQuery(productFilter, apiTimeFilter);

	return { apiTimeFilter, apiAoiBox, apiPageRequest, apiSortRequest, apiProductQuery };
}

export function prepareProductsPropertyQuery(productFilter, apiTimeFilter) {
	const apiProductQuery = []; //OR-Layer

	//AND-Layer for eoProducts:
	if ((!productFilter.hasOwnProperty('withEoFilters') || productFilter?.withEoFilters) && productFilter.eoFilters?.length >= 0) {
		apiProductQuery.push([{ key: AttributesEnum.productClass, op: ProductFilterOperator.Eq, value: ProductProductClassEnum.EO }, ...productFilter.eoFilters]);
	}
	//AND-Layer for inSituProducts:
	if ((!productFilter.hasOwnProperty('withInsituFilters') || productFilter.withInsituFilters) && productFilter.insituFilters?.length >= 0) {
		apiProductQuery.push([
			{ key: AttributesEnum.productClass, op: ProductFilterOperator.Eq, value: ProductProductClassEnum.InSitu },
			{ key: AttributesEnum.aggregationLevels, op: ProductFilterOperator.CONTAINS, value: deriveAggregationLevel(apiTimeFilter) },
			...postProcessInsituFilters(productFilter.insituFilters)
		]
		);
	}
	return apiProductQuery;
}

