/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Fig } from '../components/Figure'
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
import WebSearchFigure from './web-search-component.png'
import AoIEditor from './aoi-editor-component.png'
import { deleteIcon, aoiBoxIcon, reloadIcon, visibleAreaIcon, hintIcon, layersIcon } from '../../common/Icons/Icons'
import { Helmet } from "react-helmet";
import {
	temporalCoverageStartIcon
} from '../../data/DataProduct/DataProductAttributeIcons'

const A_MORECONDITIONS = "moreconditions"
const A_AOIEDITOR = "aoieditor"
const A_PRODUCT_TYPES = "producttypes"


export default function Web() {
	return (<>
		<Helmet>
			<title>home / doc / search</title>
		</Helmet>
		<h1>Search</h1>

		<p>The search function allows to search for a data products based on</p>
		<ul>
			<li>the data product type</li>
			<li>the time of acquisition</li>
			<li>the data footprint</li>
			<li>additional product metadata</li>
		</ul>

		<Fig src={WebSearchFigure} label="Application main screen" width="300px" />

		<Tbl caption="Search elements">
			<TblHead labels={["Search field", "Description"]} />
			<TblBody>

				<TblRow>
					<TblCell>Interval (start, end)</TblCell>
					<TblCell>The time filter consists of a start and an end time, both
						can be set independently, e.g. to restrict the result set to only recent
						data.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Interval setter {temporalCoverageStartIcon}</TblCell>
					<TblCell>Allows to quickly set intervals and to query stepwise through the timeline.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Area of Interest (AOI)</TblCell>
					<TblCell>The area of interest that a product footprint shall cover or at least intersect
						is specified by a bounding box. To define the bounding box, a separate area of interest
						editor dialog is available.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>EO data</TblCell>
					<TblCell>Allows to switch on or off if EO remote sensing products are to be searched.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_PRODUCT_TYPES} >Product types</a></TblCell>
					<TblCell>The product type or mission filter filters on the mission
						specific product type code. There are some convenient
						type groups that can be selected, e.g. there are groups that cover all
						types of a particular mission or a specific type of instrument. The type filter can also be
						set from the context menue of result entries, to restrict there result to the same type as
						the selected entry.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_MORECONDITIONS} >Add more conditions (EO)</a></TblCell>
					<TblCell>Additional conditions based on product metadata can be set and accessed through this control element.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>In-situ data</TblCell>
					<TblCell>Allows to switch on or off if in-situ sensor data is to be searched.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_PRODUCT_TYPES} >In-situ networks</a></TblCell>
					<TblCell>In-situ data is organised in networks of similar sensors. One or more networks or pre-defined network groups
						can be chosen.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_MORECONDITIONS} >Add more conditions (in-situ)</a></TblCell>
					<TblCell>Additional conditions based on aggregations attributes or channel data values can be set and accessed through
						this control element.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>


		<h1 id={A_AOIEDITOR}>Area of Interest dialogue</h1>

		<p>A dialogue for modifying the area of interest. The area of interest is defined as a bounding box. When used in a query, only products with a footprint that intersect the
			defined region of interest are listed.</p>

		<Fig src={AoIEditor} label="Area of Interest editor" />

		<Tbl caption="Area of Interest dialogue elements">
			<TblHead labels={["Element", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>{visibleAreaIcon()}</TblCell>
					<TblCell>Selects the visible map as area of interest.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{deleteIcon()}</TblCell>
					<TblCell>Drops an input.
						<ul>
							<li>Top Left, drop the area of interest.</li>
							<li>Right in AoI by name section, drop the name search input and result.</li>
						</ul>
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{aoiBoxIcon}</TblCell>
					<TblCell>Shows the area of interest bounding box as coordinates of the upper left and lower right corner.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{reloadIcon()}</TblCell>
					<TblCell>Adjusts the map view to center around the region of interest bounding box. Does nothing if no region of interest is yet defined.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Set AoI by name</TblCell>
					<TblCell>
						<p>
							Allows to search for geo-names and translate them into approximate Area of Interests. The input field requires
							at least 4 characters for a search. Names are often not unique. Alternative locations can be seen and selected
							from the "Alternatives" drop box. The delete icon clears input and alternatives list. The underlying name resolution
							service tries to return the most likely intended result as	 first entry and selects it automatically.
						</p>
						<p>The name resolution service uses data form <a href="https://www.geonames.org/">https://www.geonames.org/</a> under 
						the <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 License</a>.</p>
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{hintIcon}</TblCell>
					<TblCell>Provides a brief usage hint for the editor on functions that not immediatly obvious through other means.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>+/-</TblCell>
					<TblCell>Zoom in and out of the map.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{layersIcon()}</TblCell>
					<TblCell>Select from the available background map layers.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>


		<h1 id={A_MORECONDITIONS}>Additional conditions</h1>
		<p>Additional filter conditions are available as listed in the following table. The availability of the parameters is type dependent. When adding
			an additional conditions on a paramater with a value, this is interpreted as a requirement that any product in the result set shall have the given
			parameter with the prescribed value.</p>

		<Tbl caption="Additional metadata search fields">
			<TblHead labels={["Search field", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Product name</TblCell>
					<TblCell>The name pattern can be specified with wildcard characters where "*"
						represents any sequence of characters, including an empty one and "." represents
						a single character.
					</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>

		<h1 id={A_PRODUCT_TYPES}>Product type and type groups</h1>

		<p>When selecting product types, you can either select specific type codes or labelled type code groups.
			The available type groups and product types are listed here with information on type group content, a brief description of the codes
			and, where available, pointers to further information.
		</p>


	</>)
}