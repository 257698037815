/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


export const iconSize = { width: '1.2em', height: '1.2em' }

/**
 * The name of the service.
 */
export const serviceName = "IEOTO";

/**
 * The support e-mail address
 */

export const SUPPORT_EMAIL = "support@petabite.space"

export const NETWORKS_SLUG = "networks";
export const USER_PROFILE_SLUG = "userProfile";

export const API_TOKEN_SLUG = "apiClientAccessToken";
export const PATH_PAGES="/page"
export const PATH_PROFILE=PATH_PAGES + "/profile"
export const PATH_USER_PROFILE = PATH_PROFILE + "/" + USER_PROFILE_SLUG
export const PATH_NETWORKS = PATH_PROFILE + "/" + NETWORKS_SLUG
export const PATH_ACCESS_TOKEN = PATH_PROFILE + "/apiClientAccessToken"

export const PATH_DASHBOARD = PATH_PAGES + '/dashboard';

export const DEFAULT_DASHBOARD_NAME = "MyDashboard";