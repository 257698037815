import React, { useState } from 'react';
import { locationToBoundigBox } from '../../../state/ieotoApi';
import { DropdownBase } from '../../common/OptionsMenu/DropdownBase';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { LocationList } from '../../../utils/types/location-list';
import { BoundingBox } from '../../../utils/types';
import { IconButton } from '../../common/Icons/IconButton';
import { deleteIcon } from '../../common/Icons/Icons';

export interface PlaceOfInterestSelectorProps {
    setAreaOfInterest: (aoi: BoundingBox) => void
}

const MIN_LOCATION_NAME_CHARACTERS = 3

export const PlaceOfInterestSelector: React.FC<PlaceOfInterestSelectorProps> = ({ setAreaOfInterest }) => {
    const [inputValue, setInputValue] = useState('');
    const [locations, setLocations] = useState([] as LocationList)
    const [noResults, setNoResults] = useState(false)

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (((event.key === 'Enter') || (event.code==='Enter')) && inputValue.length > MIN_LOCATION_NAME_CHARACTERS) {
            const locations = await locationToBoundigBox(inputValue);
            setNoResults(false) 
            console.log(locations)
            if (locations.length > 0) {
                setAreaOfInterest(locations[0].boundingBox)
            } else {
                setNoResults(true)
            }
            setLocations(locations)
        }
    };

    return (
        <Row className={`align-items-center border border-4 ${noResults ? "border-tertiary" :"border-primary"}`}>
            <Col className='col-auto'>
                <label htmlFor="placeOfInterest">Set AOI by name:</label>
            </Col>
            <Col className='col-auto'>
                <input
                    type="text"
                    id="placeOfInterest"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
            </Col>
            <Col className='col-auto' style={{ zIndex: 1001 }}>
                {(locations.length > 1) && <DropdownBase toggleContent={`Alternatives (${locations.length})`}>
                    {locations && locations.map((l) => <Dropdown.Item onClick={() => {
                        setAreaOfInterest(l.boundingBox)
                    }}><span>{l.name}, {l.country ?? ''}</span></Dropdown.Item>)}
                </DropdownBase>}
                {((inputValue.length > MIN_LOCATION_NAME_CHARACTERS ) && locations.length === 0) && <span>Press Return to search</span>}
            </Col>
            <Col className='col-auto ms-auto'>

                <IconButton onClick={() => {
                    setInputValue("")
                    setLocations([] as LocationList)
                    setNoResults(false)
                }} icon={deleteIcon("Clear location")} />

            </Col>
        </Row>
    );
};
