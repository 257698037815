export function numberToString(num: number|null|undefined): string {
    if (num === undefined || num === null) {
        return "NaN"
    }
    if (num < 0.0001) {
        return "0"
    }
    if (num < 1) {
        return num.toFixed(4)
    }
    if (num < 10000) {
        return num.toFixed(2)
    }
    return (num).toPrecision(6);
}