import React, { useEffect, useState } from 'react';
import { ManagedThing } from '../../utils/types';
import { useNavigate, useParams } from 'react-router';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { PATH_NETWORKS } from '../../pbconstants';
import { getManagedThing, writeManagedThing } from '../../state/ieotoApi';
import { ThingPositionMap } from '../common/MapView/ThingPositionMap';
import { ShowItemJson } from '../common/ShowItemJson';
import { SubmitHandler, useForm } from "react-hook-form";
import { toastSuccess, toastWarning } from '../../utils/toastUtils';


interface ManagedSensorProps {
	managedThing: ManagedThing;
	canEdit: boolean
}

const ManagedSensor: React.FC<ManagedSensorProps> = ({ managedThing, canEdit }) => {
	const { formState, watch, register, handleSubmit } = useForm({
		defaultValues: { ...managedThing }
	});

	const onSubmit: SubmitHandler<ManagedThing> = async (thingData) => {
		// async request which may result error
		try {
			await writeManagedThing(thingData)
			toastSuccess(`Saved managed thing ${thingData.thingLabel}`)
		} catch (e) {
			toastWarning(`Error saving ${thingData.thingLabel}`)
			console.log(e)
		}
	}

	var infoLink = null
	if (managedThing.thingInfo?.startsWith("http")) {
		infoLink = managedThing.thingInfo
	}
	var center = null
	const [posLat, posLon] = watch(["positionLatitude", "positionLongitude"])

	if (posLat && posLon) {
		center = { latitude: posLat, longitude: posLon }
	}

	return (<Container>
		<Row className='p-3'>
			<Col>
				<h2>Thing: {managedThing.thingName}</h2>

				<p>of network {managedThing.networkName}</p>
				{infoLink && <p>Thing information: <a href={infoLink} target='_blank' rel="noreferrer">{infoLink}</a></p>}

			</Col>
			<Col className='col-auto'>
				<ShowItemJson product={managedThing} />
			</Col>
		</Row>

		<Row className='m-3 p-3 bg-insitu'>
			<Col>
				<ElementViewEdit register={register} elementName="thingId" elementLabel="Thing ID" disabled={true} />
				<ElementViewEdit register={register} elementName="thingName" elementLabel="Name" disabled={!canEdit} />
				<ElementViewEdit register={register} elementName="thingLabel" elementLabel="Label" disabled={!canEdit} />
				<ElementViewEdit register={register} elementName="thingInfo" elementLabel="Information" disabled={!canEdit} />
				<ElementViewEdit register={register} elementName="positionLatitude" elementLabel="Position latitude" disabled={!canEdit} />
				<ElementViewEdit register={register} elementName="positionLongitude" elementLabel="Position latitude" disabled={!canEdit} />
			</Col>
			<Col className='d-flex justify-content-center'>
				<ThingPositionMap thingPosition={center} />
			</Col>
		</Row>
		<Row className='m-3 p-3'>
			<Col className='col-auto ms-auto'>
				{canEdit && <Button onClick={handleSubmit(onSubmit)} disabled={!formState.isDirty}>Save</Button>}
			</Col>
		</Row>
	</Container>
	);
};

interface ManagedSensorPageProps {
	userProfile: any
}

interface ElementViewEditProps {
	elementLabel: string
	elementName: string
	register: any
	disabled: boolean
}

function ElementViewEdit({ elementLabel, elementName, register, disabled }: ElementViewEditProps) {
	return <Row className='py-2'>
		<Form.Group as={Col}>
			<Form.Label>{elementLabel}</Form.Label>
			<Form.Control {...register(elementName)} disabled={disabled}/>
		</Form.Group>
	</Row>;
}

export function ManagedSensorPage({ userProfile }: ManagedSensorPageProps) {
	const { networkName, networkOwner, thingId } = useParams();
	const navigate = useNavigate()
	const [managedThing, setManagedThing] = useState(null as unknown as ManagedThing)

	useEffect(() => {
		const getInfo = async () => {
			getManagedThing(networkOwner ?? "", networkName ?? "", thingId ?? "").then(thing => {
				if (thing) {
					setManagedThing(thing)
				}
			}
			).catch(error => {
				toastWarning(`Error retrieving managed thing ${thingId} of network ${networkName}`)
				console.log(error)
			})
		}
		getInfo()

	}, [networkName, networkOwner, thingId])

	return <>
		<Row className="p-3">
			<Col ></Col>
			<Col className="col-auto"><Button onClick={() => navigate(`${PATH_NETWORKS}/${networkName}/${networkOwner}`)}>Back</Button></Col>
		</Row>
		{managedThing && <ManagedSensor managedThing={managedThing} canEdit={managedThing.ownerName === userProfile.id} />}
	</>

}
