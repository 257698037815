import { Helmet } from "react-helmet";
import { ConnectedOnlyLoggedIn } from "../components/security/OnlyLoggedIn";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DashboardItem, deleteDashboardItem, selectUserdashboardItemsFromStoreState } from "../state/store.dashboardItems";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { useAggregation } from "../state/useAggregation";
import { OptionsMenu } from "../components/common/OptionsMenu/OptionsMenu";
import { AgeIcon, deleteIcon, ErrorIcon, permaLinkIcon, reloadIcon, selectionZoomIcon } from "../components/common/Icons/Icons";
import { pscoutApi } from "../state/pscoutApi";
import { numberToString } from "../utils/variousUtils";
import { useIntervalCounter } from "../state/useIntervalCounter";
import { useLatestValue } from "../state/useLatestValue";
import { DashboardMapView } from "../components/common/MapView/DashboardMapView";
import { BoundingBoxType } from "../utils/leafletUtils";
import { useState } from "react";
import { LoadingIcon } from "../components/common/LoadingSpinner/LoadingSpinner";
import HelperButton from "../components/common/Helper/HelperButton";
import { documentationToc } from "./DocPage";
import { ThingPosition } from "../utils/ApiTypes";
import { networkNameToColor } from "../components/common/MapView/MapView";

interface DashboardPageProps {
    dashboardItems: Array<DashboardItem>
    selectedDashboardName: string | undefined | null
}

function groupByDashboardAndNetworkName(dashboardItems: DashboardItem[]) {
    return dashboardItems.reduce((acc, item) => {
        const dashboardName = item.data.dashboardName
        const networkName = item.data.networkName;

        if (!acc[dashboardName]) {
            acc[dashboardName] = {};
        }

        if (networkName) {
            if (!acc[dashboardName][networkName]) {
                acc[dashboardName][networkName] = [];
            }

            acc[dashboardName][networkName].push(item);
        }
        return acc;
    }, {} as Record<string, Record<string, DashboardItem[]>>);
}

interface ItemPaneProps {
    item: DashboardItem
    loadCnt: number
    zoomCallback: (pos: ThingPosition) => void
}

function ItemPane({ item, loadCnt, zoomCallback }: ItemPaneProps) {
    const { loading, error, data } = useAggregation(item.data.ownerName ?? '', item.data.networkName ?? '', item.data.thingId ?? '', 0)
    const { error: latestError, loading: latestLoading, data: latestData } = useLatestValue(item.data.ownerName ?? '', item.data.networkName ?? '', item.data.thingId ?? '', loadCnt)
    const dispatch = useDispatch() as any

    const hasData = () => {
        return !error && !loading && (data !== null)
    }

    const noData = () => {
        return !error && !loading && (data === null)
    }

    const hasLatestData = () => {
        return !latestError && !latestLoading && latestData !== null
    }

    const hasPosition = () => {
        if (hasLatestData()) {
            if (latestData.position && latestData.position.longitude && latestData.position.latitude) {
                return true
            }
        }
        return false
    }

    const getLatestDisplayChannelValue = () => {
        if (latestError) {
            return "xxx"
        }
        if (data && (latestData !== null && latestData !== undefined)) {
            const displayChannelName = data.displayChannel?.channelName ?? ""
            if (displayChannelName) {
                return numberToString(latestData[displayChannelName])
            }
            return null
        }
        return null
    }

    const ageWarning = () => {
        if (latestError) {
            return false
        }
        if (latestData) {
            const infoTime = new Date(latestData.time);
            const now = new Date();
            const ageInSeconds = (now.getTime() - infoTime.getTime()) / 1000;
            return ageInSeconds > 1320
        }
        return false
    }

    const latestMeasurementTime = () => {
        if (latestData) {
            return latestData.time
        }
        return "-"
    }

    const onDashboardDrop = () => {
        dispatch(deleteDashboardItem(item))
    }

    var channelName = ""
    if (data) {
        channelName = data.displayChannel?.channelName ?? ""
    }
    return <div className="item-plate dropdown">
        <div className="item-plate-content" >

            <div className="item-label-slot">
                <div className="item-label" >{item.data.thingLabel ?? (item.data.thingName ?? item.data.thingId)}</div>
                <div className="item-menu-container">
                    <OptionsMenu>
                        {hasPosition() && <Dropdown.Item onClick={() => {
                            zoomCallback(latestData.position)
                        }}>{selectionZoomIcon()} Zoom to Thing</Dropdown.Item>}
                        {data && <Dropdown.Item href={pscoutApi.toDataProductViewUrl(data)} target="_blank">{permaLinkIcon} Resource page</Dropdown.Item>}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onDashboardDrop} target="_blank">{deleteIcon('Drop')} Drop item from dashboard</Dropdown.Item>
                    </OptionsMenu>
                </div>
            </div>


            {error && <div>Error</div>}
            {hasData() && <div>
                <div className={`item-valuetype-${channelName.length > 18 ? "long" : "short"}`}
                >{channelName}</div>
                <div className="item-value-unit">
                    {hasLatestData() && <>
                        {ageWarning() && <AgeIcon title="Data is older than 6 hours!" />}
                        {latestError && <div className="item-value-error" >!</div>}
                        <div className="item-value" title={`Measurement taken at ${latestMeasurementTime()}`}>{getLatestDisplayChannelValue()}</div>
                        <div className="item-unit">{data.displayChannel?.channelUnit}</div>
                    </>}
                    {!hasLatestData() && <>
                        {latestError && <div className="item-value-error"><ErrorIcon /></div>}
                        {!latestError && <LoadingIcon loading={latestLoading} />}
                        <div className="item-unit">{data.displayChannel?.channelUnit}</div>
                    </>}
                </div>
            </div>}
            {noData() && <div>No data</div>}
        </div>
    </div >
}

interface NetworkPanelProps {
    networkName: string
    items: DashboardItem[]
    loadCnt: number
    zoomCallback: (pos: ThingPosition) => void
}

function NetworkPanel({ networkName, items, loadCnt, zoomCallback }: NetworkPanelProps) {
    return <div className="category-container">
        <div className="category-label" ><span>{networkName}</span> </div>
        <div style={{ background: networkNameToColor(networkName), height: 4, width:"100%" }}></div>
        <div className="category-content">
            {items.map((item) => <ItemPane key={item.uuid} item={item} loadCnt={loadCnt} zoomCallback={zoomCallback} />)}
        </div>
    </div>
}

interface DashboardViewProps {
    dashboardName: string
    dashboardItems: Record<string, DashboardItem[]>
}

const BOX_MARGIN = 0.03

function DashboardView({ dashboardName, dashboardItems }: DashboardViewProps) {
    const [loadCnt, setLoadCnt] = useIntervalCounter(120)
    const [boundingBox, setBoundingBox] = useState({ south: 52, east: 12, north: 52, west: 12, isDefault: true } as BoundingBoxType)
    const networkNames = Object.keys(dashboardItems)

    const zoomToPosition = (pos: ThingPosition) => {
        setBoundingBox({ south: pos.latitude - BOX_MARGIN, east: pos.longitude + BOX_MARGIN, north: pos.latitude + BOX_MARGIN, west: pos.longitude - BOX_MARGIN })

    }

    return <>
        <Container className="dashboard-container" fluid >
            <Row className="gx-0 my-2">
                <Col> <h2 className="p-3">{dashboardName}</h2></Col>
                <Col xs="auto" className="ml-auto p-0">
                    <Button variant="secondary" onClick={() => {
                        setLoadCnt(loadCnt + 1)
                    }} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
                </Col>

                <Col xs="auto" className='p-0'>
                    <HelperButton helpTopic={documentationToc.dashboards} />
                </Col>

            </Row>
        </Container>

        <div className="dashboard-content">
            {networkNames.map((nw, index) => <NetworkPanel key={nw} networkName={nw} items={dashboardItems[nw]} loadCnt={loadCnt}
                zoomCallback={zoomToPosition} />)}
        </div>
        <Container fluid>
            <Row>
                <Col>
                    <div className="dashboard-map-container">
                        <DashboardMapView dashboardItems={dashboardItems} boundingBox={boundingBox} setBoundingBox={setBoundingBox} />
                    </div>
                </Col>
            </Row>
        </Container>

    </>
}

function DashboardPage({ dashboardItems, selectedDashboardName }: DashboardPageProps) {
    const groupedNetworkThings = groupByDashboardAndNetworkName(dashboardItems)
    const networkNames = Object.keys(groupedNetworkThings).sort()

    const theSelectedDashboardName = networkNames.includes(selectedDashboardName || '') ? selectedDashboardName : networkNames[0];

    return <Container fluid>
        {theSelectedDashboardName && <DashboardView dashboardName={theSelectedDashboardName} dashboardItems={groupedNetworkThings[theSelectedDashboardName]} />}
    </Container>
}

export function ConnectedDashboardPage() {
    const { dashboardItems } = useSelector(selectUserdashboardItemsFromStoreState, shallowEqual)
    const { dashboardName } = useParams();

    return <ConnectedOnlyLoggedIn loginHint>
        <Helmet>
            <title>IEOTO / user dashboard</title>
        </Helmet>
        <DashboardPage dashboardItems={dashboardItems} selectedDashboardName={dashboardName} />
    </ConnectedOnlyLoggedIn>
}