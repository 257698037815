export default function FeatureInfoCard({ feature }) {
    return (
        <div className={"h-100 rounded p-2 function-area-" + feature.FunctionArea}>
            <div className="card feature-card h-100" style={{ width: "18rem" }} >
                <div className="card-body">
                    <h5 className="card-title fs-4">{feature.Label}</h5>
                    <p className="card-text fs-5">{feature.Description}</p>
                </div>
                <div className="p-0 px-2 m-0 ms-auto">{feature.FunctionArea}</div>
            </div>
        </div>
    )
}