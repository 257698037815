/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer, LayersControl, Marker } from "react-leaflet";
import { MERCATOR_PROJ, projections } from './Projections'
import { networkNameToIcon, ProductMapPopup } from './MapView';
import GestureHandling from 'leaflet-gesture-handling';
import { DashboardItem } from '../../../state/store.dashboardItems';
import { useAggregation } from '../../../state/useAggregation';
import { BoundingBoxType, expandBox, insideBox, itemInsideBox, toLeafletLatLngBounds } from '../../../utils/leafletUtils';
import { createBoxFromPt } from '../../../state/store.dataProducts';
import { useLatestValue } from '../../../state/useLatestValue';
import { useIntervalCounter } from '../../../state/useIntervalCounter';
import type { ExtendedProduct, ThingPosition } from '../../../utils/ApiTypes';

function fitMapBoundsToProductWithMargin(map: any, boundingBox: BoundingBoxType) {
	if (map) {
		map.fitBounds(toLeafletLatLngBounds(boundingBox));
	}
}

interface MapBaseLayerProps {
	baseConfig: any
	children: ReactNode
}

function MapBaseLayer({ baseConfig, children }: MapBaseLayerProps) {

	return <LayersControl position="topright">
		<LayersControl.BaseLayer checked={true} name={"base"} key={"base"}>
			<TileLayer
				url={baseConfig.layers[0].url}
				minZoom={1}
				maxZoom={baseConfig.layers[0].maxZoom}
				{... (baseConfig.tileSize && { tileSize: baseConfig.tileSize })}
			/>
		</LayersControl.BaseLayer>
		{children}
	</LayersControl>

}

function area(poly: any) {
	var s = 0.0;
	var ring = poly.coordinates[0];
	for (let i = 0; i < (ring.length - 1); i++) {
		s += (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
	}
	return 0.5 * s;
}

function centroid(poly: any) {
	var c = [0, 0];
	var ring = poly.coordinates[0];
	for (let i = 0; i < (ring.length - 1); i++) {
		c[0] += (ring[i][0] + ring[i + 1][0]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
		c[1] += (ring[i][1] + ring[i + 1][1]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
	}
	var a = area(poly);
	c[0] /= a * 6;
	c[1] /= a * 6;
	return c;
}


function toPositionDashboard(polygon: any): ThingPosition {
	const c = centroid(polygon)
	return { latitude: c[0], longitude: c[1] }
}

interface ProductInDashboardMapProps {
	product: ExtendedProduct
	position: ThingPosition | null
}

/**
 * Visualizes a single product in a map.
 */
const ProductInDashboardMap: React.FC<ProductInDashboardMapProps> = ({ product, position }: ProductInDashboardMapProps) => {
	const pos = position ?? toPositionDashboard(product.spatialCoverage)

	return <Marker position={{ lat: pos.latitude, lng: pos.longitude }} icon={networkNameToIcon((product as any).networkName)} opacity={0.5}>
		<ProductMapPopup product={product} selectable={false} selected={false} />
	</Marker>


}

interface MapDashboardItemProps {
	item: DashboardItem
	locationInfoCallback: (position: ThingPosition) => void
	boundingBox: BoundingBoxType
}

function MapDashboardItem({ item, locationInfoCallback, boundingBox }: MapDashboardItemProps): ReactNode {
	const { data: aggregationData } = useAggregation(item.data.ownerName ?? '', item.data.networkName ?? '', item.data.thingId ?? '', 0)
	const [loadCnt ] = useIntervalCounter(120)
	const { data: latestData } = useLatestValue(item.data.ownerName ?? '', item.data.networkName ?? '', item.data.thingId ?? '', loadCnt)

	useEffect(() => {
		if (aggregationData && latestData && (loadCnt === 0)) {
			if (!itemInsideBox(boundingBox, latestData.position)) {
				setTimeout(() => {
					locationInfoCallback(latestData.position);
				}, 10); // to prevent trouble during initial rendering
			}
		}
		// eslint-disable-next-line 
	}, [aggregationData, locationInfoCallback, latestData, loadCnt])

	if (aggregationData) {
		return <ProductInDashboardMap product={aggregationData} position={latestData?.position} />
	} else {
		return null // just in case the data retrieval fails (silent fail)
	}
}


interface MapViewSimpleProps {
	dashboardItems: Record<string, DashboardItem[]>
	boundingBox: BoundingBoxType
	setBoundingBox: React.Dispatch<React.SetStateAction<BoundingBoxType>>
}

/**
 * Displays a map of product footprints.
 * Accepts zero or more products as input.
 */
export function DashboardMapView({ dashboardItems, boundingBox, setBoundingBox }: MapViewSimpleProps) {
	const [map, setMap] = useState(null)

	useEffect(() => {
		fitMapBoundsToProductWithMargin(map, boundingBox)
	}, [map, boundingBox])

	const networkNames = Object.keys(dashboardItems)
	let projection = projections.get(MERCATOR_PROJ)

	const newPosition = useCallback((pos: ThingPosition) => {
		const newBox = createBoxFromPt(pos)

		setBoundingBox((prevBndBx) => {
			if (prevBndBx.isDefault === true) {
				return newBox
			} else if (!insideBox(prevBndBx, newBox)) {
				const newBoundaries = expandBox(prevBndBx, newBox)
				return newBoundaries
			} else {
				return prevBndBx
			}
		})

	}, [setBoundingBox])

	return <MapContainer className='h-100 w-100 dashboard-map'
		center={projection.center} zoom={3}
		dragging={false}
		maxZoom={projection.maxZoom}
		minZoom={projection.minZoom}
		zoomControl={true} attributionControl={false} scrollWheelZoom={false}
		ref={setMap}
		whenReady={(event: any) => {
			event.target.gestureHandling.enable();
			event.target.addHandler("gestureHandling", GestureHandling);
		}
		}
		{... (projection.crs && { crs: projection.crs })}  >
		<MapBaseLayer baseConfig={projection} >
			{networkNames.map((networkName) => {
				return dashboardItems[networkName].map((di) => {
					return <MapDashboardItem key={di.uuid} item={di} locationInfoCallback={newPosition} boundingBox={boundingBox} />
				})
			})}
		</MapBaseLayer>
	</MapContainer>

}




