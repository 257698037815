/**
 * Handles Bookmarking logic
 */
export class  MenuCtrl {

    readonly isBookmarked:boolean
    readonly isBookmarklistFull:boolean
    readonly userCanBookmark:boolean

    constructor(isBookmarked:boolean, isBookmarklistFull:boolean, userCanBookmark:boolean) {
        this.isBookmarked = isBookmarked
        this.isBookmarklistFull = isBookmarklistFull
        this.userCanBookmark = userCanBookmark
    }

    /**
     * An item can be bookmarked, when the item list is not full and it is not yet bookmarked.
     * @returns whether an item can be bookmarked
     */
    canBeBookmarked() {
        return this.userCanBookmark && !this.isBookmarked && !this.isBookmarklistFull
    }

    canUserStore() {
        return this.userCanBookmark
    }
}