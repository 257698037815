/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { OptionsMenu } from '../../common/OptionsMenu/OptionsMenu'
import { downloadIcon, copyToClipboardIcon, permaLinkIcon } from '../../common/Icons/Icons'
import { pscoutApi } from '../../../state/pscoutApi'
import { ConnectedOnlyFreeUser } from '../../security/OnlyFreeUser'
import { ConnectedOnlyBasicUser } from '../../security/OnlyBasicUser'
import { PropsWithChildren } from 'react'

interface DownloadDropdownItemProps {
	product: any
	access_token: string | null
}

function DownloadDropdownItem({ product, access_token }: DownloadDropdownItemProps) {
	if (product.productType === 'PB_RLM_SHIP') {
		return <>
			<ConnectedOnlyBasicUser>
				<Dropdown.Item href={pscoutApi.toDataProductDownloadUrl(product, access_token) ?? undefined} download>{downloadIcon} Download data</Dropdown.Item>
			</ConnectedOnlyBasicUser>
			<ConnectedOnlyFreeUser>
				<Dropdown.Item disabled>{downloadIcon} Download (subscribers only)</Dropdown.Item>
			</ConnectedOnlyFreeUser>
		</>
	} else if (access_token) {
		return <Dropdown.Item href={pscoutApi.toDataProductDownloadUrl(product, access_token) ?? undefined} download>{downloadIcon} Download data</Dropdown.Item>
	} else {
		return <Dropdown.Item href={"."} download disabled>{downloadIcon} Download data</Dropdown.Item>
	}
}

interface DataProductOptionsMenuProps {
	product: any
	access_token: string | null
	withDownload: boolean
	withDetailsLink: boolean
	withCopyLink: boolean
}

/**
 * Product options menu 
 */
export function DataProductOptionsMenu({ product, access_token = null, withDownload = false, withDetailsLink = true, withCopyLink = true,
	children }: PropsWithChildren<DataProductOptionsMenuProps>) {

	const onCopyLinkToClipboardButtonClick = () => {
		navigator.clipboard.writeText(pscoutApi.toDataProductViewUrl(product))
	}
	return (<OptionsMenu >
		{withDownload && <DownloadDropdownItem product={product} access_token={access_token} /> }
		{withDetailsLink ? <Dropdown.Item href={pscoutApi.toDataProductViewUrl(product)} target="_blank">{permaLinkIcon} Resource page</Dropdown.Item> : null}
		{withCopyLink ? <Dropdown.Item onClick={onCopyLinkToClipboardButtonClick}>{copyToClipboardIcon} Copy resource link</Dropdown.Item> : null}
		{children}
	</OptionsMenu>
	)
}

