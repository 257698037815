/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


import { useState, useRef, useEffect } from "react"
import { deleteIcon, visibleAreaIcon, reloadIcon } from '../../common/Icons/Icons'
import { IconButton } from '../../common/Icons/IconButton'
import { PopupInfoButton } from '../../common/PopupInfoButton/PopupInfoButton'
import { MapContainer, Rectangle, useMapEvents, LayerGroup } from "react-leaflet";
import { MapBaseLayers } from '../../common/MapView/MapBaseLayers'
import { AoiInfoField } from '../../common/AoiInfoField/AoiInfoField'
import { toLeafletLatLngBounds, leafletMapBoundsToBoundingBox } from '../../../utils/leafletUtils'
import Button from 'react-bootstrap/Button'
import './AreaOfInterestEditor.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { isMobile } from 'react-device-detect';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PopoverBody from 'react-bootstrap/PopoverBody'
import { BDefault } from '../../common/StyleSettings'
import { PlaceOfInterestSelector } from "./PlaceOfInterestSelector";


function shiftBox(boundingBox, startPos, targetPos) {

	if (isNaN(startPos.lat) || isNaN(targetPos.lat) || isNaN(startPos.lng) || isNaN(targetPos.lng)) {
		return boundingBox
	}
	const latShift = targetPos.lat - startPos.lat
	const lonShift = targetPos.lng - startPos.lng

	const newBox = {}
	newBox.west = boundingBox.west + lonShift
	newBox.east = boundingBox.east + lonShift
	newBox.north = boundingBox.north + latShift
	newBox.south = boundingBox.south + latShift
	return newBox
}

function createBox(pt1, pt2) {
	const boundingBox = {}
	boundingBox.west = Math.min(pt1.lng, pt2.lng)
	boundingBox.east = Math.max(pt1.lng, pt2.lng)
	boundingBox.north = Math.max(pt1.lat, pt2.lat)
	boundingBox.south = Math.min(pt1.lat, pt2.lat)
	return boundingBox
}

let isRepositioning = false
let isSettingAoi = false

function SearchAoiBox({ boundingBox, setBoundingBox }) {
	const [dragstart, setDragstart] = useState(0);
	const [point1, setPoint1] = useState(0);
	const rectangleRef = useRef()
	const parentMap = useMapEvents({
		mousedown(e) {
			e.originalEvent.preventDefault();
			//console.log("the mouse is down ", e)
			if (e.originalEvent.button !== 0 && e.originalEvent.ctrlKey) {
				setBoundingBox(null)
			} else if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
				isSettingAoi = true
				isRepositioning = false
				parentMap.dragging.disable();
				setPoint1(e.latlng)
			} else if (isRepositioning) {
				parentMap.dragging.disable()
				setDragstart(e.latlng)
			}
		},
		mouseup(e) {
			parentMap.dragging.enable();
			if (isSettingAoi) {
				const newBox = createBox(point1, e.latlng)
				isSettingAoi = false
				setBoundingBox(newBox)
			} else if (isRepositioning) {
				parentMap.dragging.enable()
				const newBox = shiftBox(boundingBox, dragstart, e.latlng)
				setBoundingBox(newBox)
				isRepositioning = false
			}
		},
		mousemove(e) {
			if (isRepositioning) {
				const newBox = shiftBox(boundingBox, dragstart, e.latlng)
				// the order of the following calls is important
				setDragstart(e.latlng)
				setBoundingBox(newBox)
				rectangleRef.current.setBounds([[newBox.south, newBox.west], [newBox.north, newBox.east]])
			}
		}
	})

	return (boundingBox ?

		<LayerGroup><Rectangle pathOptions={{ "className": "aoibox" }} bounds={[[boundingBox.south, boundingBox.west], [boundingBox.north, boundingBox.east]]} ref={rectangleRef}
			eventHandlers={{
				mousedown: (e) => {
					isRepositioning = true
				},
			}} /> </LayerGroup> : null
	)
}

function RemoveAoiButton({ removeAoi }) {
	return (<IconButton onClick={removeAoi} icon={deleteIcon("Drop this area of interest")} />);
}

function MapBoundsToAoiButton({ useMapRegion }) {
	return (<IconButton onClick={useMapRegion} icon={visibleAreaIcon("Use visible region as area of interest.")} />);
}

function MapZoomMaintainer({ setMapBounds }) {
	const map = useMapEvents({
		moveend() {
			setMapBounds(map.getBounds())
		}
	})
	return null
}

function RepositionButton({ reposFun }) {
	return <IconButton onClick={reposFun} icon={reloadIcon("Refocus on product footprints.")} />
}

function isMacintosh() {
	return navigator.platform.indexOf('Mac') > -1
}

var ctrlKeyLabel = "Ctrl"
if (isMacintosh()) {
	ctrlKeyLabel = "Cmd"
}

function fitMapBoundsToAoI(map, aoiBoundingBox = null) {
	if (aoiBoundingBox) {
		map.fitBounds(toLeafletLatLngBounds(aoiBoundingBox, 0.2))
	}
}

function AoiEditorComponent({ mapBounds, setMapBounds, removeAoi, boundingBox, newBoundingBox }) {
	const [map, setMap] = useState(null)

	function setMapRegionAsAoi() {
		const boundingBox = leafletMapBoundsToBoundingBox(map.getBounds())
		newBoundingBox(boundingBox)
	}

	const explicitFitToAoI = () => {
		fitMapBoundsToAoI(map, boundingBox)
	}

	function AoiControlsCol({ useMapRegion, removeAoi }) {
		return <Col className="col-auto">
			<MapBoundsToAoiButton useMapRegion={useMapRegion} />
			<RemoveAoiButton removeAoi={removeAoi} />
		</Col>
	}

	var containerStyle = { width: "41rem" }

	if (isMobile) {
		containerStyle = { width: "100%" }
	}


	return (
		<Container className="p-2" style={containerStyle}>
			<Row className="align-items-center">
				<AoiControlsCol useMapRegion={setMapRegionAsAoi} removeAoi={removeAoi} />
				<Col className="me-auto">
					<AoiInfoField boundingBox={boundingBox}
						noBoxContent={!isMobile && <span>Use <kbd className="bg-light">{ctrlKeyLabel}+drag mouse</kbd> to draw an area or press the eye icon.</span>} w100={false} />
				</Col>
				<Col className="col-auto px-1">
					<RepositionButton reposFun={explicitFitToAoI} />
				</Col>
				{!isMobile &&
					<Col className="col-auto pl-1">
						<PopupInfoButton  >
							<div>Drag to position the map. Use <kbd>{ctrlKeyLabel}+drag mouse</kbd> to draw an area of interest or press the eye icon.</div>
						</PopupInfoButton>
					</Col>}
			</Row>
			<Row>
				<Col><PlaceOfInterestSelector setAreaOfInterest={(bb)=> {
					newBoundingBox(bb)
					fitMapBoundsToAoI(map,bb)
				}}/></Col>
			</Row>
			<Row className="py-3" >
				<Col className={isMobile?"col-12":"col-auto"}>
					<MapContainer className={isMobile ? "aoieditor-mobile" : "aoieditor"} bounds={mapBounds} scrollWheelZoom={true} ref={setMap}
					>
						<MapBaseLayers selected='bkg' />
						<SearchAoiBox boundingBox={boundingBox} setBoundingBox={newBoundingBox} />
						<MapZoomMaintainer setMapBounds={setMapBounds} />
					</MapContainer>
				</Col>
			</Row>
		</Container>
	)
}

function calcInitialMapBoundsState(boundingBox) {
	if (boundingBox) {
		return toLeafletLatLngBounds(boundingBox, 0.1)
	} else {
		return toLeafletLatLngBounds({ south: 53.12, west: 9.98, north: 53.36, east: 10.98 })
	}
}

/**
 * Provides an Editor to set the Area of Interest
 */
export function AreaOfInterestEditor({ boundingBox, onChange }) {
	// The currently set region of interest. The box of interestes may come from a stored query.
	const [boundingBx, setBoundingBx] = useState(boundingBox)
	// the currently set map boundaries. Is preset to a default area or am aria around the area of interest
	const [mapBounds, setMapBounds] = useState(calcInitialMapBoundsState(boundingBx))

	useEffect(() => {

		setBoundingBx(boundingBox)

	}, [boundingBox])

	function newBoundingBox(boundingBox) {
		setBoundingBx(boundingBox)
		onChange(boundingBox)
	}

	function deactivateAoi() {
		setBoundingBx(null)
	}

	function editorCloses() {
		onChange(boundingBx)
	}

	if (isMobile) {
		return <div >
			<AoiEditorComponent mapBounds={mapBounds} setMapBounds={setMapBounds} removeAoi={deactivateAoi} boundingBox={boundingBx} newBoundingBox={newBoundingBox} />
		</div>
	}
	else {
		return (
			<OverlayTrigger trigger="click" placement="auto" rootClose={true} className="px-0 w-100" onExit={editorCloses}
				overlay={<Popover id="popover-basic" >

					<PopoverBody className="bg-secondary" style={{width:"43rem"}}>
						<AoiEditorComponent mapBounds={mapBounds} setMapBounds={setMapBounds} removeAoi={deactivateAoi} boundingBox={boundingBx} newBoundingBox={setBoundingBx} />

					</PopoverBody>
				</Popover>}
			>
				<Button variant={BDefault} className="bg-primary w-100"><AoiInfoField boundingBox={boundingBx} noBoxContent="Click to set AoI" /> </Button>
			</OverlayTrigger>)
	}
}




