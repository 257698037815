/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState, useEffect } from "react"
import { LayersControl, MapContainer, GeoJSON, Popup, LayerGroup, TileLayer, Pane, Rectangle, useMap } from "react-leaflet";
import toBBox from 'geojson-bounding-box'
import { productNameIcon } from '../../data/DataProduct/DataProductAttributeIcons';
import { MercatorProjection, ArcticProjection, AntarcticProjection, allZoomIcon, selectionZoomIcon } from '../Icons/Icons';
import { IconButton } from '../Icons/IconButton'
import { MERCATOR_PROJ, ARCTIC_PROJ, ANTARCTIC_PROJ, projections } from './Projections'
import { GestureHandling } from "leaflet-gesture-handling";
import SectionTopRow from '../SectionTopRow'
import { pscoutApi } from '../../../state/pscoutApi'
import { BoundingBoxType, toLeafletLatLngBounds } from '../../../utils/leafletUtils'
import Form from 'react-bootstrap/Form'
import { dataProductsSelected } from '../../../state/store.dataProducts'
import { useDispatch } from 'react-redux'
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import L from 'leaflet';
import HelperButton from '../Helper/HelperButton';
import { documentationToc } from '../../../pages/DocPage';
import { MyMiddleEllipsis } from '../tools/MyMiddleEllipsis';
import { ExtendedProduct } from '../../../utils/ApiTypes';
import type { GeoJsonTypes } from 'geojson'

// L.Icon.Default.prototype.options.iconUrl = '/marker-icon.svg'
// L.Icon.Default.prototype.options.iconRetinaUrl = '/marker-icon.svg'
// L.Icon.Default.prototype.options.shadowUrl = '/shadow-icon.svg'

const ICON_SIZE = 20

function createColoredIcon(color: string) {
	return new L.Icon({
		iconUrl: `/marker-icon-${color}.svg`,
		iconRetinaUrl: `/marker-icon-${color}.svg`,
		iconSize: [ICON_SIZE, ICON_SIZE], // Größe des Icons
		iconAnchor: [Math.round(ICON_SIZE / 2), Math.round(ICON_SIZE / 2)], // Ankerpunkt des Icons
		popupAnchor: [1, -ICON_SIZE / 2], // Ankerpunkt des Popups
		shadowUrl: `/marker-icon-${color}.svg`, // Pfad zur Schatten-Icon-Datei
		shadowSize: [ICON_SIZE, ICON_SIZE], // Größe des Schatten-Icons
		shadowAnchor: [Math.round(ICON_SIZE / 2), Math.round(ICON_SIZE / 2)], // Ankerpunkt des Schatten-Icons
	});
}


const redIcon = createColoredIcon('red');
const blueIcon = createColoredIcon('blue');
const greenIcon = createColoredIcon('green');
const pinkIcon = createColoredIcon('pink');
const greyIcon = createColoredIcon('grey');
const orangeIcon = createColoredIcon('orange');
const greenyellowIcon = createColoredIcon('greenyellow');
const mediumOrchidIcon = createColoredIcon('mediumorchid');


const icons = [redIcon, orangeIcon, greenIcon, pinkIcon, greyIcon, greenyellowIcon, mediumOrchidIcon]
const colors = ['red', "orange", "green", "pink", "grey", "greenyellow", "mediumorchid"]

function lessThan180(array: Array<number>) {
	return (array.filter((x) => Math.abs(x) >= 180)).length === 0
}

export function maxExtent(array: Array<Array<number>>) {
	var min0 = array[0][0]
	var max0 = array[0][0]
	var min1 = array[0][1]
	var max1 = array[0][1]

	for (var n = 1; n < array.length; n++) {
		min0 = Math.min(min0, array[n][0])
		max0 = Math.max(max0, array[n][0])
		min1 = Math.min(min1, array[n][1])
		max1 = Math.max(max1, array[n][1])
	}
	return (Math.max(max0 - min0, max1 - min1))
}

export function toPresentationGeometry(polygon: any) {
	// Identify if something is to be presented as a Marker or as a polygon by using a limit on its extent
	var rval = polygon
	if (polygon.coordinates[0]?.length > 0 && polygon.coordinates[0]?.length < 6 &&
		polygon.coordinates[0][0].length === 2 && lessThan180(polygon.coordinates[0][0]) && (maxExtent(polygon.coordinates[0]) < 0.001)) {
		rval = { type: "Point", coordinates: polygon.coordinates[0][0], properties: {} }
	}
	return rval
}

function stringToColorIndex(str: string) {
	// Einfache Hash-Funktion
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	// Modulo 6, um eine Zahl zwischen 0 und 5 zu erhalten
	return Math.abs(hash % icons.length);
}

export function networkNameToColor(networkName: string) {
	if (!networkName) {
		return "blue"
	}
	const rval = colors[stringToColorIndex(networkName)]
	return rval
}

export function networkNameToIcon(networkName: string) {
	if (!networkName) {
		return blueIcon
	}
	return icons[stringToColorIndex(networkName)]
}


export function ProductMapPopup({ product, selectable, selected }: {
	product: ExtendedProduct
	selectable: boolean
	selected: boolean
}) {
	const dispatch = useDispatch()
	const onSelectionSwitchChange = (event: any) => {
		dispatch(dataProductsSelected({ affectedProductIds: [product.id], isSelected: event.target.checked }))
	}

	return <Popup className='product-info-map-popup'>
		<div className={'d-flex justify-content-between align-items-center mb-2 ' + ((product as any).networkName ? "bg-insitu" : "bg-eo")}>
			<div className='fs-4 px-2'>{(product as any).networkName ? `In-situ data` : "EO data"}</div>

			{selectable && <Form.Check
				type="switch"
				id={`pop-switch-${product.id}`}
				checked={selected}
				onChange={onSelectionSwitchChange}
				className='pe-2'
			/>
			}
		</div>
		{(product as any).thingName && <div className='fs-5 p-2'>{(product as any).thingName}</div>}
		<a href={pscoutApi.toDataProductViewUrl(product)} target="_blank" rel="noreferrer">
			<div className='d-flex flex-row'> <div className='pe-2'>{productNameIcon}</div>  <div style={{ width: "300px" }}>
				<MyMiddleEllipsis><span className="ellipseMe">{product.productName}</span></MyMiddleEllipsis></div></div>

		</a>
	</Popup>
}

export interface ProductInMapProps {
	product: ExtendedProduct
	pathClass: string
	selected: any
	selectable: any
}

/**
 * Visualizes a single product in a map.
 */
export const ProductInMap: React.FC<ProductInMapProps> = ({ product, pathClass, selected, selectable }: ProductInMapProps) => {


	const featureData = {
		"type": "Feature" as GeoJsonTypes,
		"properties": {},
		"geometry": toPresentationGeometry(product.spatialCoverage)
	}


	/* className={pathClass} */
	return <GeoJSON style={{ className: pathClass }} data={featureData} key={product.id}
		pointToLayer={(feature, latlng) => {
			//console.log(product)
			return L.marker(latlng, {
				icon: networkNameToIcon((product as any).networkName),
				opacity: selected ? 1.0 : 0.5,
			});
		}} >
		<ProductMapPopup product={product} selectable={selectable} selected={selected} />

	</GeoJSON>
}


/**
 * A layer of footprints.
 */

interface FootprintsProps {
	products: Array<ExtendedProduct>
	selectedProductIds: Array<string>
	selectable: boolean
}
function Footprints({ products, selectedProductIds, selectable }: FootprintsProps) {
	return <LayersControl.Overlay checked name="Footprints">
		<LayerGroup>
			{
				products.map((product) => {
					return <ProductInMap pathClass="product-footprint" product={product} key={product.id} selected={selectedProductIds.includes(product.id ?? '')} selectable={selectable} />
				})
			}
		</LayerGroup>
	</LayersControl.Overlay>
}

function AoiLayer({ aoiBoundingBox }: { aoiBoundingBox: BoundingBoxType }) {
	return <LayerGroup>
		<Rectangle pathOptions={{ "className": "aoibox" }} bounds={toLeafletLatLngBounds(aoiBoundingBox)} />
	</LayerGroup >
}

/**
 * A layer of footprints from selected products
 */

function Selected({ products, selectedProductIds, selectable }: FootprintsProps) {

	return <Pane name="overlay-selected" style={{ zIndex: 401 }}>
		<LayersControl.Overlay checked name="Selected">
			<LayerGroup>
				{
					products.filter((element) => selectedProductIds.includes(element.id ?? '')).map((value) => {
						return <ProductInMap pathClass="selected-footprint" product={value} key={value.id} selected={true} selectable={selectable} />
					})
				}
			</LayerGroup>
		</LayersControl.Overlay>
	</Pane>
}

export function fitMapBoundsToProducts(map: any, products: Array<ExtendedProduct>, aoiBoundingBox: BoundingBoxType | null = null) {

	if (aoiBoundingBox && products?.length === 0) {
		map.fitBounds(toLeafletLatLngBounds(aoiBoundingBox, 0))
	} else if (products?.length > 0) {
		let boxes = products.map((p) => toBBox(p.spatialCoverage))
		let minBox = boxes[0]
		for (var bo of boxes) {
			minBox = [Math.min(minBox[0], bo[0]), Math.min(minBox[1], bo[1]), Math.max(minBox[2], bo[2]), Math.max(minBox[3], bo[3])]
		}
		map.fitBounds([
			[minBox[1], minBox[0]],
			[minBox[3], minBox[2]]
		]);
	}
}

function ProjectionButton({ label, setProjFun }: { label: any, setProjFun: any }) {
	return <IconButton onClick={setProjFun} icon={label} />
}

function RepositionButton({ reposFun }: { reposFun: any }) {
	return <IconButton onClick={reposFun} icon={allZoomIcon("Refocus on product footprints.")} />
}

function ZoomSelectionButton({ reposFun }: { reposFun: any }) {
	return <IconButton onClick={reposFun} icon={selectionZoomIcon("Focus on selected products footprints.")} />
}


function MapBaseLayers({ baseConfig, children }: { baseConfig: any, children: ReactNode }) {

	return <LayersControl position="topright">
		{
			baseConfig.layers.map((layerConf: any, index: number) => {
				return <LayersControl.BaseLayer checked={index === 0} name={layerConf.name} key={layerConf.name}>
					<TileLayer
						url={layerConf.url}
						attribution={layerConf.attribution}
						minZoom={1}
						maxZoom={layerConf.maxZoom}
						{... (baseConfig.tileSize && { tileSize: baseConfig.tileSize })}
					/>
				</LayersControl.BaseLayer>
			})
		}
		{children}
	</LayersControl>

}

function MapSetterComponent({ setMapFun }: { setMapFun: any }) {
	const map = useMap()
	setMapFun(map)
	return null
}


interface MapViewProps {
	aoiBoundingBox: BoundingBoxType
	products: Array<ExtendedProduct>
	selectedProductIds: Array<string>
	loading: boolean
	selectable: boolean,
}

/**
 * Displays a map of product footprints.
 * Accepts zero or more products as input.
 */
export function MapView({ aoiBoundingBox, products, selectedProductIds = [], loading, selectable = true }: MapViewProps) {

	const [map, setMap] = useState(null)
	const [projection, setProjection] = useState(projections.get(MERCATOR_PROJ))
	// to manage complete repainting of the mapcontainer, when the projection changes
	const [remountMap, setRemountMap] = useState(false)

	// causes asynchronous map redraw after redraw has been requested
	useEffect(() => {
		remountMap && setTimeout(() => setRemountMap(false), 500);
	}, [remountMap]);

	// Note: if products and aoiBoundingBox are part of the dependency
	// there are too many callst to fitMapBounds, not fully understood why
	useEffect(() => {
		if (!loading && map) {
			fitMapBoundsToProducts(map, products, aoiBoundingBox)
		}
	}, [loading, map]) // eslint-disable-line react-hooks/exhaustive-deps

	const explicitFitToProducts = () => {
		fitMapBoundsToProducts(map, products, aoiBoundingBox)
	}

	const explicitFitToSelectedProducts = () => {
		const selectedProducts = products.filter(p => selectedProductIds.includes(p.id ?? ''))
		if (selectedProducts.length > 0) {
			fitMapBoundsToProducts(map, selectedProducts)
		}
	}

	function changeProjection(projectionName: string) {
		let newProjection = projections.get(projectionName)
		if (projection !== newProjection) {
			setProjection(newProjection)
			setRemountMap(true) // initiates complete redraw
		}
	}

	function setTheMap(map: any) {
		//console.log("setting the map")
		//console.log(map.getZoom())
		//setMap(map)
	}

	return <Container className="h-100">
		<SectionTopRow>
			<Col xs={12} className="ml-0 container">
				<Row className="pl-2">
					<Col className="col-auto px-1"><ProjectionButton label={<ArcticProjection />} setProjFun={() => changeProjection(ARCTIC_PROJ)} /></Col>
					<Col className="col-auto  px-1"><ProjectionButton label={<MercatorProjection />} setProjFun={() => changeProjection(MERCATOR_PROJ)} /></Col>
					<Col className="me-auto col-auto px-1"><ProjectionButton label={<AntarcticProjection />} setProjFun={() => changeProjection(ANTARCTIC_PROJ)} /></Col>
					{selectedProductIds.length > 0 && <Col className="col-auto  px-1"><ZoomSelectionButton reposFun={explicitFitToSelectedProducts} /></Col>}
					{products.length > 0 && <Col className="col-auto  px-1"><RepositionButton reposFun={explicitFitToProducts} /></Col>}
					<Col className='col-auto'><HelperButton helpTopic={documentationToc.map} /> </Col>
				</Row>
			</Col>
		</SectionTopRow>
		<Row className="p-0 h-100">
			<Col className="h-100">
				{!remountMap &&
					<MapContainer className='w-100 mapview'
						bounds={toLeafletLatLngBounds({ south: 53.12, west: 9.98, north: 53.36, east: 10.98 })}
						zoom={2} scrollWheelZoom={false}
						maxZoom={projection.maxZoom}
						minZoom={projection.minZoom}
						ref={setMap}
						whenReady={(arg: any) => {
							fitMapBoundsToProducts(arg.target, products, aoiBoundingBox);
							arg.target.gestureHandling.enable();
							arg.target.addHandler("gestureHandling", GestureHandling);
						}
						}
						{... (projection.crs && { crs: projection.crs })}  >
						<MapBaseLayers baseConfig={projection} >
							{aoiBoundingBox && <AoiLayer aoiBoundingBox={aoiBoundingBox} />}
							{selectedProductIds.length > 0 && <Selected products={products} selectedProductIds={selectedProductIds} selectable={selectable} />}
							<Footprints selectable={selectable} products={products} selectedProductIds={selectedProductIds} />
						</MapBaseLayers>
						<MapSetterComponent setMapFun={setTheMap} />
					</MapContainer>
				}
			</Col>
		</Row>
	</Container>

}
