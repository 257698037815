/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import './SafetyButton.scss'
import { DropdownBase } from '../OptionsMenu/DropdownBase';

export function SafetyButton({ onClick, icon, label }) {
	return (
		<DropdownBase toggleContent={<>{icon} {label}</>} toggleClass='safetyButton'>
			<Dropdown.Item onClick={onClick}>{label}</Dropdown.Item>
		</DropdownBase>
	)
}
