import React from 'react';
import { Form, Row } from "react-bootstrap";
import { DateTimeInput } from "../../common/DateTimeInput/DateTimeInput";
import { IntervalSetter } from "../../common/DateTimeInput/IntervalSetter";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RelTimeInput } from "./RelTimeInput";
import { isTimelimitSet } from "./RelTimeInput";
import { temporalCoverageStartIcon } from '../DataProduct/DataProductAttributeIcons';
import { TimeFilterType } from '../../../state/storeTypes';

export interface RelTimeDiff {
    days: number
    hours: number
}



type SensingTimeIntervalProps = {
	activeTab: string|null
    setActiveTab: (activeTab: string) => void
    timeFilter: TimeFilterType
    onChange: (newTimeFilter: TimeFilterType) => void
};

const formGroupClass = "col py-2 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12"

const ABSOLUTE = 'absolute'
const RELATIVE = 'relative'
const YESTERDAY_RELATIVE_TIME:RelTimeDiff = { days: -1, hours: 0 }
const NOW_RELATIVE_TIME:RelTimeDiff = { days: 0, hours: 0 }

export function SensingTimeInputFilter({activeTab, setActiveTab, timeFilter, onChange }: SensingTimeIntervalProps) {
    const intervalStart= timeFilter.intervalStart
    const intervalEnd= timeFilter.intervalEnd
	const relIntervalStart= timeFilter.relIntervalStart ??  YESTERDAY_RELATIVE_TIME 
	const relIntervalEnd=  timeFilter.relIntervalEnd ?? NOW_RELATIVE_TIME
	const isRelTimelimit = isTimelimitSet(timeFilter.relIntervalStart) || isTimelimitSet(timeFilter.relIntervalEnd)
	const currentTab = activeTab ?? (isRelTimelimit ? RELATIVE : ABSOLUTE)
	
    return <Tabs id="timeinterval" activeKey={currentTab} onSelect={(k) => setActiveTab(k as string)} className="col-12"
        mountOnEnter={true} unmountOnExit={true}>

        <Tab eventKey={ABSOLUTE} title={<span>Absolute {temporalCoverageStartIcon}</span>}>
            {currentTab === ABSOLUTE && <Row>

                <Form.Group controlId="intervalStart" className={formGroupClass}>
                    <Form.Label>Interval start <span className="text-muted">(UTC)</span></Form.Label>
                    <div className="border row py-1">
                        <DateTimeInput initialValue={intervalStart}
                            maxDateValue={intervalEnd}
                            onValueChange={(value:any) => onChange({ intervalStart: value, intervalEnd: intervalEnd, relIntervalStart: undefined, relIntervalEnd: undefined})} />

                        <IntervalSetter intervalStart={intervalStart}
                            intervalEnd={intervalEnd}
                            onIntervalChange={(timeFilter: any) => {
                                onChange(timeFilter as TimeFilterType)
                            }}
                        />
                    </div>
                </Form.Group>

                <Form.Group controlId="intervalEnd" className={formGroupClass}>
                    <Form.Label>Interval end <span className="text-muted">(UTC)</span></Form.Label>

                    <div className="row p-1 align-items-center">
                        <DateTimeInput initialValue={intervalEnd}
                            minDateValue={intervalStart}
                            onValueChange={(value:any) => onChange({ intervalStart: intervalStart, intervalEnd: value, relIntervalStart: undefined, relIntervalEnd: undefined })} />

                        <IntervalSetter intervalStart={intervalStart}
                            intervalEnd={intervalEnd}
                            onIntervalChange={(timeFilter: any) => {
                                onChange(timeFilter as TimeFilterType)
                            }}
                        />
                    </div>
                </Form.Group>
            </Row>}
        </Tab>
        <Tab eventKey={RELATIVE} title={<span>Relative {temporalCoverageStartIcon}</span>}>
            {currentTab === RELATIVE && <Row>
                {(intervalStart || intervalEnd) && <span className="text-muted">Setting any value, clears the absolute filter.</span>}
                <Form.Group controlId="intervalStart" className={formGroupClass}>
                    <Form.Label>Interval start</Form.Label>
                    <div className="border row p-1">
                        <RelTimeInput relTime={relIntervalStart} relIntervalStartLimit={undefined} relIntervalEndLimit={relIntervalEnd}
                            valueChanged={(newVal) => onChange({ intervalStart: undefined, intervalEnd: undefined, relIntervalStart: newVal, relIntervalEnd: relIntervalEnd })} />
                    </div>
                </Form.Group>

                <Form.Group controlId="intervalEnd" className={formGroupClass}>
                    <Form.Label>Interval end</Form.Label>

                    <div className="row p-1 align-items-center">
                        <RelTimeInput relTime={relIntervalEnd} relIntervalStartLimit={relIntervalStart} relIntervalEndLimit={undefined}
                            valueChanged={(newVal) => onChange({ intervalStart: undefined, intervalEnd: undefined, relIntervalStart: relIntervalStart, relIntervalEnd: newVal })} />
                    </div>
                </Form.Group>
            </Row>}

        </Tab>

    </Tabs>
}