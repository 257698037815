
import { useEffect, useState } from "react";
import { pscoutApi } from "./pscoutApi";

export function useLatestValue(ownerName: string, networkName: string, thingId: string, loaCnt: number) {
    const [data, setData] = useState(null as unknown as any);
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setError(false)
        const fetchData = async () => {
            try {
                setLoading(true)
                const rval = await pscoutApi.getLatestThingValue(ownerName, networkName, thingId)
                setData(rval)   
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(true)
            }
        };
        if (ownerName !== null && networkName !== null && thingId !== null) {
            fetchData()
        }
    }, [ownerName, networkName, thingId, loaCnt]);
    return { loading, error, data }
}
