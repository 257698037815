
import { useEffect, useState } from "react";
import { pscoutApi } from "./pscoutApi";
import { ProductTypesInfo } from "../utils/types";
import { toastWarning } from "../utils/toastUtils";

export const emptyProductTypesInfo = {
	groups: [{
        label: "Types could not be loaded!",
        collections: [""]
    }],
	productTypes: [{
        typeCode: "Types could not be loaded!",
        collections: [
            ""
        ],
        originLabel: "",
        hasThumbnailAndQuicklook: false
    },]
}

export function useProductTypesInfo() {
    const [productTypesInfo, setProductTypesInfo] = useState(emptyProductTypesInfo as ProductTypesInfo);
    const [ptiError, setError] = useState(false)
    const [ptiLoading, setLoading] = useState(false)
    useEffect(() => {
        setError(false)
        const fetchData = async () => {
            try {
                setLoading(true)
                const theData = await pscoutApi.getProductTypesInfo()
                setProductTypesInfo(theData)
                setLoading(false)
            } catch (error) {
                toastWarning("Could not load product types info. May require page reload.")
                setError(true)
            }
        };
        fetchData()
    },[]);
    return { ptiLoading, ptiError, productTypesInfo }
}


export function useNetworkTypesInfo() {
    const [networkTypesInfo, setProductTypesInfo] = useState(emptyProductTypesInfo as ProductTypesInfo);
    const [ntiError, setError] = useState(false)
    const [ntiLoading, setLoading] = useState(false)
    useEffect(() => {
        setError(false)
        const fetchData = async () => {
            try {
                setLoading(true)
                const theData = await pscoutApi.getNetworkTypesInfo()
                setProductTypesInfo(theData)
                setLoading(false)
            } catch (error) {
                toastWarning("Could not load network types info. May require page reload.")
                setError(true)
            }
        };
        fetchData()
    },[]);
    return { ntiLoading, ntiError, networkTypesInfo }
}
