/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { ReactNode } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import './OptionsMenu.scss'
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';

interface DropdownBaseProps {
    children: ReactNode; // Die Inhalte des Dropdown-Menüs
    toggleContent: ReactNode|string
    toggleClass?: string
    drop?: DropDirection
}

export function DropdownBase({ children, toggleContent, toggleClass='optionsMenu', drop='down' }:DropdownBaseProps) {
    return (
        <Dropdown drop={drop}>
            <Dropdown.Toggle variant="white" className={toggleClass} 
                id="dropdown-basic"
                aria-haspopup="true" aria-expanded="false"
                >{toggleContent}</Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}
